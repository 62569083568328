import * as THREE from 'three';
import App from '../App.js';

export default class MoonBlank
{
    constructor(model, group)
    {
        this.app = new App();
        this.resources = this.app.resources;
        this.debug = this.app.debug;
        this.scene = this.app.scene;
        this.model = model;
        this.group = group;
        this.time = this.app.time;
        this.camera = this.app.camera;
        this.world = this.app.world;

        //cache
        this.instance;


        // // Debug
        // if(this.debug.active)
        // {
        //     this.debugFolder = this.debug.ui.addFolder('moon');  
        // }

        // Resource
        this.resource = this.resources.items[model];
        this.setModel();
    }

    setModel()
    {
        this.instance = this.resource ? this.resource.scene : this.createCube();
        this.instance.name = this.model;

        this.texture2 = this.resources.items['gameMoonsTexture'];
        this.texture2.encoding = THREE.sRGBEncoding;
        this.texture2.flipY = false;

        this.testMaterial2 = new THREE.MeshPhongMaterial({
            transparent: true,
            opacity: 1,
        });

        this.testMaterial2.shininess = 0.5;


        const c = this.instance.children[2];
                c.material = this.testMaterial2;
                c.material.map = this.texture2;
                this.normalMap2 = this.resources.items['gameMoonsNormal'];
                this.normalMap2.flipY = false;
                this.aoMap2 = this.resources.items['gameMoonsAo'];
                this.aoMap2.flipY = false;
                this.testMaterial2.normalMap = this.normalMap2;
                this.testMaterial2.aoMap = this.aoMap2;
                c.scale.set(0.015, 0.015, 0.015);
                c.receiveShadow = true;
                c.material.transparent = true;
                c.material.opacity = 1;

                c.frustumCulled = false;
    }

    createCube() 
    {
        return new THREE.Mesh(
            new THREE.SphereBufferGeometry(1.1,16,16),
            new THREE.MeshBasicMaterial()
        )
    }
}