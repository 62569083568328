import * as dat from 'lil-gui';

export default class Debug
{
    constructor()
    {
        if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
        {
            this.active = window.location.hash === '#dev'
        }
        
        if(this.active)
        {
            this.ui = new dat.GUI({
                closed: true,
                width: 400,
            })
        }
    }
}