import * as THREE from 'three';
import App from './App.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';

export default class Renderer
{
    constructor()
    {
        this.app = new App();
        this.canvas = this.app.canvas;
        this.sizes = this.app.sizes;
        this.scene = this.app.scene;
        this.camera = this.app.camera;
        this.overlay;




        this.setOverlay();
        // this.addToScene();

        this.instance = this.setInstance();

        
        let RenderTargetClass = null

        if(this.instance.getPixelRatio() === 1 && this.instance.capabilities.isWebGL2)
        {
            RenderTargetClass = THREE.WebGLMultisampleRenderTarget
            // console.log('Using WebGLMultisampleRenderTarget')
        }
        else
        {
            RenderTargetClass = THREE.WebGLRenderTarget
            // console.log('Using WebGLRenderTarget')
        }
    


        const renderTarget = new THREE.WebGLRenderTarget(
            this.sizes.width,
            this.sizes.height,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.LinearFilter,
                format: THREE.RGBAFormat
            }
        )

        /**
         * Post processing
         */
        this.effectComposer = new EffectComposer(this.instance, renderTarget)
        this.effectComposer.setSize(this.sizes.width, this.sizes.height)
        this.effectComposer.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));

        const renderPass = new RenderPass(this.scene, this.camera.instance);

        this.effectComposer.addPass(renderPass);

        // const displacementPass = new ShaderPass(this.overlayMaterial)
        // displacementPass.material.uniforms.uTime.value = 0
        // this.effectComposer.addPass(displacementPass)

        // console.log(this.instance.info);
    }

    setInstance()
    {
        const instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true
        });


        // instance.shadowMap.enabled = true
        // instance.shadowMap.type = THREE.PCFShadowMap
        // instance.physicallyCorrectLights = true
        // instance.outputEncoding = THREE.sRGBEncoding
        // instance.toneMapping = THREE.ReinhardToneMapping
        // instance.toneMappingExposure = 1.5
        // instance.setSize(this.sizes.width, this.sizes.height)
        // instance.setPixelRatio(Math.min(window.devicePixelRatio, 2))


        instance.physicallyCorrectLights = true;
        instance.outputEncoding = THREE.sRGBEncoding;
        instance.toneMapping = THREE.CineonToneMapping;
        instance.toneMappingExposure = 1.75;
        instance.shadowMap.enabled = true;
        instance.shadowMap.type = THREE.PCFSoftShadowMap;
        instance.setClearColor('#000000');
        instance.setSize(this.sizes.width, this.sizes.height);
        instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));

        return instance;
    }

    resize()
    {
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
    }

    addToScene()
    {
        this.scene.add(this.overlay);
    }

    setOverlay()
    {
        this.overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);


        this.overlayMaterial = new THREE.ShaderMaterial({
            uniforms:
            {
                tDiffuse: { value: null },
                uTime: { value: null }
            },
            vertexShader:  `
            varying vec2 vUv;
    
            void main()
            {
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    
                vUv = uv;
            }
        `,
            fragmentShader: `
            uniform sampler2D tDiffuse;
            uniform float uTime;
    
            varying vec2 vUv;
    
            void main()
            {
                vec2 newUv = vec2(
                    vUv.x,
                    vUv.y + sin(vUv.x * 10.0 + uTime) * 0.001
                );
                vec4 color = texture2D(tDiffuse, newUv);
    
                gl_FragColor = color;
            }
        `
        })
        this.overlayMaterial.transparent = true;
        this.overlay = new THREE.Mesh(this.overlayGeometry, this.overlayMaterial);
    }

    update()
    {
        this.instance.render(this.scene, this.camera.instance);
        // console.log(this.instance.info.render.calls > 13 ? this.instance.info : 'nope');
        // console.log(this.instance.info);
        // this.effectComposer.render();
        // this.overlay.material.uniforms.uTime.value = this.app.time.elapsed * 0.001;
    }
}