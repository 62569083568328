import './style.css'

import App from './App/App.js'
import gsap from 'gsap';

const appliction = new App(document.querySelector('canvas.webgl'));

const on = document.getElementById('on');
const off = document.getElementById('off');
on.style.cssText = 'display: none';
off.style.cssText = 'display: none';

const loadingText = document.getElementById('loadingText');
const gamesText = document.getElementById('games-text');
gamesText.style.opacity = 0;
// loadingText.textContent = 'Loading...';
let x = 0;
const timeline = gsap.timeline({
    onComplete: () => {
        timeline.play(1.75)
    }
});
timeline
        .to(loadingText, {duration: 1, textContent: 'Loading.', opacity: 0.1, scaleX: 1, scaleY: 1.1 + x, rotateX: 0})
        .to(loadingText, {duration: 2, textContent: 'Loading.', opacity: 1, scaleX: 1, scaleY: 1.1 + x, rotateX: 0})
        .to(loadingText, {duration: 2, textContent: 'Loading..', opacity: 0.1, scaleX: 1, scaleY: 1.1 + x, rotateX: 45})
        .to(loadingText, {duration: 2, textContent: 'Loading...', opacity: 1, scaleX: 1, scaleY: 1.1 + x, rotateX: 0})
        .to(loadingText, {duration: 2, textContent: 'Loading....', opacity: 1, scaleX: 1.1, scaleY: 1.3 + x, rotateX: 0})
        .to(loadingText, {duration: 2, textContent: 'Loading.....', opacity: 0.1, scaleX: 1, scaleY: 1.2 + x, rotateX: 45})
        .to(loadingText, {duration: 2, textContent: 'Loading......', opacity: 1, scaleX: 1.2, scaleY: 1.5 + x, rotateX: 0})
        .to(loadingText, {duration: 2, textContent: 'Loading.......', opacity: 1, scaleX: 1.1, scaleY: 1.3 + x, rotateX: 0})
        .to(loadingText, {duration: 2.5, textContent: 'Loading........', opacity: 0.1, scaleX: 2.0, scaleY: 4.0 + x, rotateX: 45})
        .to(loadingText, {duration: 3, textContent: 'Loading.........', opacity: 1, scaleX: 1.6, scaleY: 2.0 + x, rotateX: 0})
        .to(loadingText, {duration: 4, textContent: 'Loading..........', opacity: 1, scaleX: 3.0, scaleY: 8.0 + x, rotateX: 0})
        .to(loadingText, {duration: 5, textContent: 'Loading...........', opacity: 0.1, scaleX: 1.5, scaleY: 6.0 + x, rotateX: 45})
        .to(loadingText, {duration: 6, color: 'yellow', textContent: 'Loading............', opacity: 1, scaleX: 5.5, scaleY: 12.0 + x, rotateX: 0})
        .to(loadingText, {duration: 10, color: 'red', textContent: 'Arghhhhhhh............', opacity: 1, scaleX: 1.0, scaleY: 1.1 + x, rotateX: 1080, rotateY: 1080})
        .to(loadingText, {duration: 5, color: 'orange', textContent: 'Mmmmmmm.', opacity: 0.1, scaleX: 1.7, scaleY: 2.1 + x, rotateX: 0}, '+=5.0')
        .to(loadingText, {duration: 6, color: 'aliceblue', textContent: 'Maybe switch it off, and back on again.', opacity: 1, scaleX: 1.2, scaleY: 1.3 + x, rotateX: 0}, '+=5.0')
        .to(loadingText, {duration: 5, color: 'yellow', textContent: '.........................', opacity: 1, scaleX: 1.7, scaleY: 1.4 + x, rotateX: 0, rotateY: 0}, '+=5.0')
        .to(loadingText, {duration: 15, color: 'pink', textContent: '....well this is embarrassing', opacity: 1, scaleX: 0.1, scaleY: 0.21+ x, rotateX: 0, rotateY: 0})
        .to(loadingText, {duration: 4, color: 'aliceblue', textContent: 'Loading', opacity: 1, scaleX: 1, scaleY: 1.1 + x, rotateX: 0})

timeline.play(1.75);

appliction.resources.on('ready', () => {
    const app = document.getElementById('app');
    app.style.cssText = 'display: inherit';
    loadingText.style.cssText = 'display: none';
})