import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import App from '../../App';
import EventEmitter from '../../Utils/EventEmitter';
export default class GameMoonsText extends EventEmitter
{
    constructor(group)
    {
        gsap.registerPlugin(ScrollTrigger);

        super();

        this.app = new App();
        this.group = group;
        this.scene = this.app.scene;
        this.mouse = this.app.mouse;
        this.page = this.app.scrollPageNumbers.page;
        this.scrollManager = this.app.scrollManager;


        this.start();

    }

    start()
    {

        const text = document.getElementById('games-text');
        text.style.opacity = 0;
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".webgl",
                start: window.innerHeight * 8,
                end: window.innerHeight * 12,
                scrub: true,
                markers: false
            }
            });
            tl.to(text, {x: 0, duration: 0, y: window.innerHeight * 0.25, z: 0, duration: 0, opacity: 0})
            tl.to(text, {x: 0, y: 0, z: -1.5, duration: 40, opacity: 0.7})
            tl.to(text, {x: 0, y: 0, z: -1.5, duration: 40, opacity: 0.7})
            tl.to(text, {x: 0, duration: 0, y: window.innerHeight * 0.25, z: 0, duration: 40, opacity: 0})
    }

}