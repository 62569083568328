import EventEmitter from '../Utils/EventEmitter';
import * as THREE from 'three';
import App from '../App';
export default class PointerSprite extends EventEmitter
{
    constructor(scene, mouse, camera)
    {
        super();

        this.app = new App();
        this.scene = scene;
        this.mouse = mouse;
        this.camera = camera;
        this.scrollManager = this.app.scrollManager;

        // cache
        this.targetPositionVector3 = new THREE.Vector3();
        this.page = this.app.scrollPageNumbers.page;
        this.instance;
        this.noGalaxyArray = [];
        // this.intervalSet = false;

        //raycaster
        this.direction = new THREE.Vector3(0,0,-1);
        this.raycaster = new THREE.Raycaster(new THREE.Vector3(), this.direction, 0, 10);
        this.raycaster.camera = this.camera.instance;


        this.start();
        this.scrollDetect();
        this.runMouseDetect();
        this.runMouseUpDetect();
        this.runMouseClickDetect();
        // this.runTouchDetect();
        this.runTouchStart();
        this.runTouchEnd();
    }

    start()
    {

        this.mobileTestTouchElement = document.querySelector('.touch');

        this.instance = this.createCube();
        this.instance.scale.set(0.2, 0.2, 0.2);
        this.instance.visible = false;
        this.scene.add(this.instance);
    }

    scrollDetect()
    {
        this.scrollManager.on('three-scroll', () => {
            this.instance.position.x = 0;
            this.instance.position.y = 0;
            this.instance.position.z = 10;
            this.raycaster.set(this.instance.position,  this.direction);
        })
    }

    runMouseDetect() 
    {
        this.mouse.on('mouse-move', () => 
            {
                this.page = this.app.scrollPageNumbers.page;
                this.targetPositionVector3 = new THREE.Vector3(this.mouse.positionInThreeSpace.x, this.mouse.positionInThreeSpace.y, this.mouse.positionInThreeSpace.z);
                this.instance.position.set(this.targetPositionVector3.x, this.targetPositionVector3.y, this.targetPositionVector3.z);
                this.raycaster.set(this.instance.position,  this.direction);
            }
        )
    }

    runTouchStart()
    {
        this.mouse.on('touch-start', () => {

            this.page = this.app.scrollPageNumbers.page;
            this.targetPositionVector3 = new THREE.Vector3(this.mouse.positionInThreeSpace.x, this.mouse.positionInThreeSpace.y, this.mouse.positionInThreeSpace.z);
            this.instance.position.set(this.targetPositionVector3.x, this.targetPositionVector3.y, this.targetPositionVector3.z);
            this.raycaster.set(this.instance.position,  this.direction);
        })
    }

    // runTouchDetect()
    // {
    //     this.mouse.on('touch-move', () => 
    //         {

    //         }
    //     )
    // }

    runMouseUpDetect()
    {
        this.mouse.on('mouse-up', () => 
        {
            if(this.checkPageNumber && this.name === 'poolSharkMoonModel') 
            {
                document.getElementById('poolShark').click();
            }
            else if(this.name === 'tinCircusMoonModel')
            {
                document.getElementById('tinCircus').click();
            }


            this.page = this.app.scrollPageNumbers.page;

        })
    }

    runMouseClickDetect()
    {
        this.mouse.on('mouse-click', () => 
        {
            this.raycaster.set(this.instance.position,  this.direction);
            this.page = this.app.scrollPageNumbers.page;

        })
    }

    runTouchEnd()
    {
        this.mouse.on('touch-end', () => {
            // this.instance.visible = true;

            if(this.checkPageNumber && this.name === 'poolSharkMoonModel') 
            {
                document.getElementById('poolShark').click();
            }
            else if(this.name === 'tinCircusMoonModel')
            {
                document.getElementById('tinCircus').click();
            }


            // this.instance.position.set(new THREE.Vector3());

            // if(this.intervalSet === false)
            // {
            //     this.intervalSet = true;

            //     setTimeout(() => {
            //         this.intervalSet = false;
            //         this.instance.visible = false;
            //         this.raycaster.set(this.instance.position, new THREE.Vector3(0, 0, 1));
            //     }, 500);
            // }

        })
    }

    checkPageNumber(pageNumber) 
    {
        switch(pageNumber)
        {
            case 9:
                return true;
            case 10:
                return true;
            case 11:
                return true;
            case 12:
                return true;
            default:
                return false;
        }
    }



    createCube() 
    {
        return new THREE.Mesh(
            new THREE.BoxBufferGeometry(1,1,1),
            new THREE.MeshBasicMaterial()
        )
    }


    update()
    {

        //this bend the ray to act more orthegraphic
        const xModifier = this.instance.position.x > 0 ? window.innerWidth * 0.0002 : -window.innerWidth * 0.0002;
        this.direction.set(xModifier, 0, -1);


        let array = [...this.scene.children[3].children, ...this.scene.children[4].children];
        let intersects = this.raycaster.intersectObjects( array );

        if(intersects.length > 0) 
        {
            intersects.forEach((x) => {
                x.object.traverse((c) => {
                    if(c instanceof THREE.Mesh) 
                    {
                        if(c.parent.userData.name && c.parent.children[5])
                        {
                            if(c.parent.children[5].userData.clickable)
                            {
                                this.name = c.parent.userData.name;
                                this.mobileTestTouchElement.innerHTML = this.name;

                                c.parent.parent.children[1].intensity = 1.25;

                                if(this.scene.children[10])
                                {
                                    this.scene.children[10].intensity = 0.1;
                                }
                            }

                        }
                        else
                        {
                            this.name = '';
                            // this.mobileTestTouchElement.innerHTML = 'hit1';
                        }
                    }
                })
            })
        }
        else
        {
            this.name = '';
            // this.mobileTestTouchElement.innerHTML = 'hit3';
            if(this.scene.children[3].children[1])
            {
                this.scene.children[3].children[1].intensity = 0.1
            }

            if(this.scene.children[4].children[1])
            {
                this.scene.children[4].children[1].intensity = 0.1
            }

            if(this.scene.children[10])
            {
                this.scene.children[10].intensity = 1.25;
            }

        }

        intersects = [];
        array =  [];
    }
}