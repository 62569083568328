import * as THREE from 'three';
import gsap from 'gsap';
import App from '../../App';
export default class HeavyBucketMoonText
{
    constructor(group)
    {
        this.app = new App();
        this.group = group;
        this.scene = this.app.scene;
        this.mouse = this.app.mouse;
        this.page = this.app.scrollPageNumbers.page;


        //cach
        this.scoreText;
        this.showText = false;
        this.instanceXDefault = -11.0;
        this.instance2XDefault = -11.0;
        this.instance3XDefault = 0.5;
        this.instanceStartPosition = new THREE.Vector3(window.innerWidth * 0.1, 150, 0);
        this.instance2StartPosition = new THREE.Vector3(-window.innerWidth * 0.1, 175, 0);
        this.instance3StartPosition = new THREE.Vector3(0, -150, 0);

        this.start();

    }

    start()
    {
        this.mouse.on('mouse-move', () => {
            // console.log('heavy bm text ');
            this.page = this.app.scrollPageNumbers.page;
                this.textIn();
        })

        this.highScoreHtml = document.getElementById('new-high-score');
        this.clickToPlay = document.getElementById('click-to-play');
        this.orScroll = document.getElementById('or-scroll');

        this.clickToPlay.style.cssText = 'display: inherit';
        this.orScroll.style.cssText = 'display: inherit';


        gsap.set(this.highScoreHtml, { x: this.instance3StartPosition.x, y: this.instance3StartPosition.y, z: this.instance3StartPosition.z})
        gsap.set(this.clickToPlay, {textContent: 'CLICK TO PLAY',  x: this.instanceStartPosition.x, y: this.instanceStartPosition.y, z: this.instanceStartPosition.z, opacity: 0})
        gsap.set(this.orScroll, { textContent: 'OR SCROLL', x: this.instance2StartPosition.x, y: this.instance2StartPosition.y, z: this.instance2StartPosition.z, opacity: 0})
    }

    textIn()
    {
        if(this.showText === false && (this.page === 1 || this.page === 18))
        {
            if(!this.rocket)
            {
                this.rocket = this.app.world.rocket;
            }

            if(this.rocket.rocketDead)
            {
                this.showText = true;

                const tl = gsap.timeline();
                tl
                .to(this.clickToPlay, { duration: 1, delay: 1, x: -this.instanceXDefault, y: this.instanceStartPosition.y, z: 0, opacity: 1, ease: 'power3' })
                .to(this.orScroll, { duration: 1, delay: 0 , x: -this.instance2XDefault, y: this.instance2StartPosition.y, z: this.showText ? 0 : this.instance2StartPosition.z, ease: 'power3', opacity: 1}, "+=0.5")



                // error check
                setInterval(() => {
                    if(this.showText === false)
                    {
                        gsap.to(this.clickToPlay, { duration: 1, delay: 0, x: this.instanceStartPosition.x, y: this.instanceStartPosition.y, z: this.instanceStartPosition.z , opacity: 0});
                        gsap.to(this.orScroll, { duration: 1, delay: 0, x: this.instance2StartPosition.x, y: this.instance2StartPosition.y, z: this.instance2StartPosition.z, opacity: 0});
                    }
                }, 500);
            }

            if(!this.scoreText)
            {
                 this.scoreText = this.app.world.scoreText;
            }
                if(this.scoreText.newHighScore)
                {
                    this.scoreText.newHighScore = false;
                    const tl = gsap.timeline();
                    tl.to(this.highScoreHtml, { x: this.instance3StartPosition.x, y: this.instance3StartPosition.y, z: this.instance3StartPosition.z , duration: 0, opacity: 0})
                      .to(this.highScoreHtml, { duration: 2, delay: 0, x: -this.instance3XDefault, y: 0, z: 0, opacity: 0.7, rotateX: 360, color: '#f0f8ff'})
                      .to(this.highScoreHtml, { duration: 2, delay: 0, x: -this.instance3XDefault, y: 0, z: 0, opacity: 0.7, rotateX: 360, color: '#f0f8ff'})
                      .to(this.highScoreHtml, { x: this.instance3StartPosition.x, y: this.instance3StartPosition.y, z: this.instance3StartPosition.z, duration: 2, delay: 1, opacity: 0, color: '#FF0A10'})

                    tl.play(0);
                }
        }
    }

    textFuckOff()
    {
        if(this.showText === true)
        {
            this.showText = false;
            gsap.to(this.clickToPlay, { duration: 1, delay: 0, x: this.instanceStartPosition.x, y: this.instanceStartPosition.y,  z: this.instanceStartPosition.z , opacity: 0 });
            gsap.to(this.orScroll, { duration: 1, delay: 0, x: this.instance2StartPosition.x, y: this.instance2StartPosition.y,  z: this.instance2StartPosition.z, opacity: 0 });
        }
        else if(this.orScroll.z < 9.5)
        {
            gsap.to(this.orScroll, { duration: 1, delay: 0, x: this.instance2StartPosition.x, y: this.instance2StartPosition.y,  z: this.instance2StartPosition.z, opacity: 0 });
        }
    }
}
