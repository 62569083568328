import * as THREE from 'three';
import App from '../App.js';

export default class Environment
{
    constructor()
    {
        this.app = new App();
        this.scene = this.app.scene;
        this.resources = this.app.resources;
        this.debug = this.app.debug;
        
        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('environment');
        }

        this.setSunLight();
        // this.setEnvironmentMap()
    }

    setSunLight()
    {
        this.sunLight = new THREE.DirectionalLight('#ffffff', 1);
        this.sunLight.castShadow = true;
        // console.log(this.sunLight.shadow);
        this.sunLight.shadow.camera.far = 13;
        this.sunLight.shadow.mapSize.set(256, 256);
        this.sunLight.shadow.normalBias = 0.05;
        // this.sunLight.shadow.blurSamples = 0;
        this.sunLight.position.set(3.5, 2, - 1.25);
        this.sunLight.intensity = 1.25;
        this.sunLight.position.set(3.318, 4.361, 4.622);
        this.scene.add(this.sunLight);
    

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .add(this.sunLight, 'intensity')
                .name('sunLightIntensity')
                .min(0)
                .max(10)
                .step(0.001)
            
            this.debugFolder
                .add(this.sunLight.position, 'x')
                .name('sunLightX')
                .min(- 5)
                .max(5)
                .step(0.001)
            
            this.debugFolder
                .add(this.sunLight.position, 'y')
                .name('sunLightY')
                .min(- 5)
                .max(5)
                .step(0.001)
            
            this.debugFolder
                .add(this.sunLight.position, 'z')
                .name('sunLightZ')
                .min(- 5)
                .max(5)
                .step(0.001)
        }
    }

    // setEnvironmentMap()
    // {
    //     this.environmentMap = {}
    //     this.environmentMap.intensity = 0.746
    //     this.environmentMap.texture = this.resources.items.environmentMapTexture
    //     this.environmentMap.texture.encoding = THREE.sRGBEncoding
        
    //     this.scene.environment = this.environmentMap.texture

    //     // this.updateGuiTweaks();

    //     // Debug
    //     if(this.debug.active)
    //     {
    //         this.debugFolder
    //             .add(this.environmentMap, 'intensity')
    //             .name('envMapIntensity')
    //             .min(0)
    //             .max(4)
    //             .step(0.001)
    //             .onChange(this.updateGuiTweaks)
    //     }
    // }

    updateGuiTweaks = () =>
    {
        this.scene.traverse((child) =>
        {
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
            {
                child.material.envMap = this.environmentMap.texture
                child.material.envMapIntensity = this.environmentMap.intensity
                child.material.needsUpdate = true
            }
        })
    }
}