export default class RendererCompile
{
    constructor(renderer, scene, camera)
    {
        this.renderer = renderer;
        this.scene = scene;
        this.camera = camera;

        this.renderer.instance.compile(this.scene, this.camera);
    }
}