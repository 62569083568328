import * as THREE from 'three';
import App from "./App";
import EventEmitter from './Utils/EventEmitter';
import Helper from './Utils/Helper';

export default class EnemySpawner extends EventEmitter
{
    constructor()
    {
        super();

        this.app = new App();
        this.sizes = this.app.sizes;
        this.world = this.app.world;
        this.camera = this.app.camera;
        this.time = this.app.time;
        this.helper = new Helper();
        this.mouse = this.app.mouse;
        this.resources = this.app.resources;


        //cache
        this.spawnPositionsNorth = 6;
        this.spawnPositionsArrayPageOne = [this.spawnPositionsNorth];
        const valueVec3 = this.helper.getThreePositionFromScreenPosition(new THREE.Vector3(this.sizes.width, this.sizes.height, 0), this.camera);
        this.wideAmount = valueVec3.x * 30
        this.zPlane = 0;

        this.spawnerOff = false;

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.rocket = this.app.world.rocket;
            this.enemyOneMaster = this.app.world.enemyOneMaster;
        })


        // setTimeout(() => {
        //     this.rocket = this.app.world.rocket;
        // }, 500);

        this.start()
        this.runScreenResize();
    }

    start()
    {
        for (let i = 0; i < this.spawnPositionsNorth; i++) {
            this.spawnPositionsArrayPageOne[i] = this.createSpawnPositions(i);
        }

        // store spawmn origin position

    }

    runScreenResize() 
    {
        this.sizes.on('resize', () => {
            this.mouse.checkOffTheScreenLeftPosition();
            this.wideAmount = ((this.mouse.positionOffTheLeftOfTheScreenInThreeSpace.x * 100 * 0.25) * -1);
        })
    }

    createSpawnPositions(index)
    {
        switch(index)
        {
            case 0:
                return new THREE.Vector3(-this.wideAmount, this.wideAmount, this.zPlane);
            case 1:
                return new THREE.Vector3(0, this.wideAmount, this.zPlane);
            case 2:
                return new THREE.Vector3(this.wideAmount, this.wideAmount, this.zPlane);
            case 3:
                return new THREE.Vector3(this.wideAmount, 0, this.zPlane);
            case 4:
                return new THREE.Vector3(0, -this.wideAmount, this.zPlane);
            case 5:
                return new THREE.Vector3(-this.wideAmount, -this.wideAmount, this.zPlane);
        }
    }

    update()
    {        
        if(this.rocket)
        {
            if(this.rocket.rocketDead === false && this.spawnerOff === false && this.time.elapsedSeconds % 3 === 0)
            {
                this.spawnEnemy();
            }
        }else{

            this.rocket = this.app.world.rocket;
        }
    }

    spawnEnemy()
    {
        // return;
        if(!this.enemyOneMaster)
        {
            this.enemyOneMaster = this.app.world.enemyOneMaster;
        }

        if(this.enemyOneMaster)
        {
            const ranNum = this.helper.getRandomInt(0, this.enemyOneMaster.enemyOneArrayAmount - 1);
            const x = this.enemyOneMaster.enemyOneArray[ranNum];
            x.instance.children[0].active = true;
            x.instance.children[0].originPosition = new THREE.Vector3(
                this.spawnPositionsArrayPageOne[this.helper.getRandomInt(0, this.spawnPositionsNorth)].x,
                this.spawnPositionsArrayPageOne[this.helper.getRandomInt(0, this.spawnPositionsNorth)].y,
                this.spawnPositionsArrayPageOne[this.helper.getRandomInt(0, this.spawnPositionsNorth)].z
                )

                if(!this.audioManager)
                {
                    this.audioManager = this.app.world.audioManager;
                }
                if(this.audioManager.audio['spawn'].file.isPlaying)
                {
                    this.audioManager.audio['spawn'].file.pause();
                    this.audioManager.audio['spawn'].file.stop();
                }
                this.audioManager.audio['spawn'].file.setVolume(0.7);
                this.audioManager.audio['spawn'].file.play();
        }
    }
}