import App from '../App.js';

export default class ScrollPageNumbers 
{
    constructor() 
    {
        this.app = new App();
        this.scrollManager = this.app.scrollManager;

        //cache
        this.windowScrollY = 0;
        this.centerOfEachPageScollValue = [];
        this.page = 1;
        this.numberOfPages = 18;

        this.setCenterOfEachPageScollValue();
        this.setup()
    }
    setup() 
    {
        this.scrollManager.on('three-scroll', (e) => {
            this.windowScrollY = window.scrollY;
            this.centerOfEachPageScollValue.forEach((value, index) => {
                if(this.windowScrollY > value) {
                    this.page = index + 1;
                }
            })
        })
    }
    setCenterOfEachPageScollValue() 
    {
        const halfScreen = window.innerHeight / 2;

        for (let i = 0; i < this.numberOfPages; i++) 
        {
            let value = ((i) * window.innerHeight) - halfScreen;
            if(value < 0) {
                value = 0;
            }
            this.centerOfEachPageScollValue.push(value);
        }

        // console.log(this.centerOfEachPageScollValue);
    }
}