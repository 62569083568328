import * as THREE from 'three';
import App from '../App.js';

export default class Moon
{
    constructor(model, group)
    {
        this.app = new App();
        this.resources = this.app.resources;
        this.debug = this.app.debug;
        this.scene = this.app.scene;
        this.model = model;
        this.group = group;
        this.time = this.app.time;
        this.camera = this.app.camera;
        this.world = this.app.world;

        //cache
        this.instance;
        this.pointlightPoolShark;
        this.pointlightTinCircus;
        this.count = 0;
        this.pointLightIntensity = 4;


        // // Debug
        // if(this.debug.active)
        // {
        //     this.debugFolder = this.debug.ui.addFolder('moon');  
        // }

        // Resource
        this.resource = this.resources.items[model];
        this.setModel();
        // this.setAnimation();
    }

    setModel()
    {
        this.instance = this.resource ? this.resource.scene : this.createCube();
        this.instance.name = this.model;
        this.instance.userData['name'] = this.model;

        //set in scroll timeline
        this.instance.scale.set(1, 1, 1);

        // console.log(this.instance);

        this.instance.traverse((c) =>
        {
            if(c instanceof THREE.Mesh)
            {
                //all materials stuff
                c.receiveShadow = true;
                c.material.transparent = true;
                c.material.opacity = 1;
                c.frustumCulled = false;


                if(this.model === 'heavyBucketMoonModel') {
                    this.texture = this.resources.items['heavyBucketMoonTexture'];
                    this.texture.encoding = THREE.sRGBEncoding;
                    this.texture.flipY = false;
                    this.normalMap = this.resources.items['heavyBucketMoonNormalMap'];
                    this.normalMap.flipY = true;
                    this.aoMap = this.resources.items['heavyBucketMoonAoMap'];
                    this.aoMap.flipY = false;
                    this.testMaterial = new THREE.MeshPhongMaterial({
                        transparent: true,
                        opacity: 1
                    });
                    this.testMaterial.shininess = 0.5;
                    this.testMaterial.map = this.texture;
                    this.testMaterial.normalMap = this.normalMap;
                    this.testMaterial.aoMap = this.aoMap;
                    this.testMaterial.aoMapIntensity = 150;
                    this.texture.generateMipmaps = false;
                    this.texture.minFilter = THREE.NearestFilter;
                    c.material = this.testMaterial;
                    c.scale.set(0.01, 0.01, 0.01);

                }

                if(this.model === 'poolSharkMoonModel') {
                    this.texture3 = this.resources.items['gameMoonsTexture'];
                    this.texture3.encoding = THREE.sRGBEncoding;
                    this.texture3.flipY = false;
                    this.normalMap3 = this.resources.items['gameMoonsNormal'];
                    this.normalMap3.flipY = false;
                    this.aoMap3 = this.resources.items['gameMoonsAo'];
                    this.aoMap3.flipY = false;
                    this.testMaterial3 = new THREE.MeshPhongMaterial({
                        transparent: true,
                        opacity: 1
                    });
                    this.testMaterial3.shininess = 0.5;
                    this.testMaterial3.map = this.texture3;
                    this.testMaterial3.normalMap = this.normalMap3;
                    this.testMaterial3.aoMap = this.aoMap3;
                    this.testMaterial3.aoMapIntensity = 150;
                    this.texture3.generateMipmaps = false;
                    this.texture3.minFilter = THREE.NearestFilter;

                    if(c.name === 'poolsharkMoon1')
                    {
                        c.material.transparent = true;
                        c.material.opacity = 1;
                        c.material = this.testMaterial3;
                        c.userData['clickable'] = false;
                        this.instance.rotation.y = Math.PI;
                    }

                    //add point light
                    this.pointlightPoolShark = new THREE.PointLight();
                    this.pointlightPoolShark.distance = 6;
                    this.pointlightPoolShark.decay = 0.1;
                    this.pointlightPoolShark.intensity = this.pointLightIntensity;
                    this.pointlightPoolShark.name = 'pointlightPoolShark';
                    this.pointlightPoolShark.position.set(0, 5.5, 0.8);

                }

                if(this.model === 'tinCircusMoonModel') {
                    this.texture4 = this.resources.items['gameMoonsTexture'];
                    this.texture4.encoding = THREE.sRGBEncoding;
                    this.texture4.flipY = false;
                    this.normalMap4 = this.resources.items['gameMoonsNormal'];
                    this.normalMap4.flipY = false;
                    this.aoMap4 = this.resources.items['gameMoonsAo'];
                    this.aoMap4.flipY = false;
                    this.testMaterial4 = new THREE.MeshPhongMaterial({
                        transparent: true,
                        opacity: 1
                    });
                    this.testMaterial4.shininess = 0.5;
                    this.testMaterial4.map = this.texture4;
                    this.testMaterial4.normalMap = this.normalMap4;
                    this.testMaterial4.aoMap = this.aoMap4;
                    this.testMaterial4.aoMapIntensity = 150;
                    this.texture4.generateMipmaps = false;
                    this.texture4.minFilter = THREE.NearestFilter;

                    if(c.name === 'poolsharkMoon1'){ 
                        c.material.transparent = true;
                        c.material.opacity = 1;
                        c.material = this.testMaterial4;
                        c.name = 'tinCircuskMoon1'
                        c.userData.name = 'tinCircuskMoon1';
                        c.userData['clickable'] = false;

                        this.pointlightTinCircus = new THREE.PointLight();
                        this.pointlightTinCircus.distance = 6;
                        this.pointlightTinCircus.decay = 0.1;
                        this.pointlightTinCircus.intensity = this.pointLightIntensity;
                        this.pointlightTinCircus.name = 'pointlightTinCircus';
                        this.pointlightTinCircus.position.set(0, 5.5, 0.8);
                        this.group.add(this.pointlightTinCircus);

                        this.instance.rotation.y = Math.PI;
                    }
                }
            }
        })
    }

    createCube() 
    {
        return new THREE.Mesh(
            new THREE.BoxBufferGeometry(1,1,1),
            new THREE.MeshBasicMaterial()
        )
    }

    // switchLightOn()
    // {
    //         if(this.model === 'poolSharkMoonModel' && this.pointlightPoolShark)
    //         {
    //             this.pointlightPoolShark.intensity = this.pointLightIntensity;
    //             console.log('hit1');
    //         }
    //         if(this.model === 'tinCircusMoonModel' && this.pointlightTinCircus)
    //         {
    //             console.log('hit2');
    //             this.pointlightTinCircus.intensity = this.pointLightIntensity;
    //         }  
    // }
}