import * as THREE from 'three'
import { Vector3 } from 'three'
import EventEmitter from './Utils/EventEmitter.js'
import App from './App.js'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera extends EventEmitter
{
    constructor(group)
    {
        super();

        this.app = new App();
        this.group = group;
        this.sizes = this.app.sizes;
        this.time = this.app.time;
        this.scene = this.app.scene;
        this.canvas = this.app.canvas;
        this.debug = this.app.debug;
        this.scrollManager = this.app.scrollManager;
        this.mouse = this.app.mouse;
        this.setInstance();

        //cache
        this.windowScrollY = 0;
        this.scrollCrossoverMultiplier = 1;
        this.cullingDistance = 100;

        // this.setControls()
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('camera');
            this.setupDebugUi();
        }
        this.scrollSetup();
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, this.cullingDistance);
        this.instance.position.set(0, 2, 10);
        this.group.add(this.instance);
    }

    setControls()
    {
        // this.controls = new OrbitControls(this.instance, this.canvas)
        // this.controls.enableDamping = true
    }
    setupDebugUi() 
    {
        this.debugFolder.add(this.instance.position, 'x').min(-40).max(45).step(0.1).name('camera x');
        this.debugFolder.add(this.instance.position, 'y').min(0.1).max(45).step(0.1).name('camera y');
        this.debugFolder.add(this.instance.position, 'z').min(-40).max(45).step(0.1).name('camera z');
    }
    scrollSetup() 
    {
        // this.scrollCommands();

        this.scrollManager.on('three-scroll', (e) => {
            this.windowScrollY = window.scrollY;

            // this.scrollCommands();
        })
    }

    scrollCommands() 
    {
        if(this.windowScrollY <= (window.innerHeight * this.scrollCrossoverMultiplier)) {
            this.instance.position.y = (this.windowScrollY * 0.01) + 1;
            this.instance.position.x = (this.windowScrollY * 0.1) - (window.innerHeight* 0.1);
        }
        else if(this.windowScrollY > window.innerHeight * this.scrollCrossoverMultiplier)
        {
            this.instance.position.y = (this.windowScrollY * 0.01) + 1;
            this.instance.position.x = (this.windowScrollY * 0.01) - (window.innerHeight* 0.01);
        }
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    }

    update()
    {
        // this.controls.update()



        this.instance.lookAt(new THREE.Vector3(0,0,0));


        this.instance.position.x = (Math.sin((this.time.elapsed) * 0.000005) * 0.5);
        this.instance.position.y = ((Math.sin((this.time.elapsed) * 0.00004) * 1.5)) + 2;
        this.instance.position.z = ((Math.sin((this.time.elapsed) * 0.00003) * 0.25)) + 10;
        // if(!this.mouse)
        // {
        //     this.mouse = this.app.mouse;
        // }

        // this.instance.position.x = (this.mouse.position.x * 0.0001) * 10;

        // this.instance.position.y = (this.mouse.position.y * 0.0001) * 10;

        // this.instance.position.z = ((Math.sin((this.time.elapsed) * 0.00003) * 0.25)) + 10;
    }
}