import * as THREE from 'three';
import App from '../App';
import AudioManager from '../Utils/AudioManager';
import EventEmitter from '../Utils/EventEmitter';
import Helper from '../Utils/Helper';
import Bullet from './Bullet';
export default class EnemyOne
{
    constructor(startPosition)
    {
        this.className = 'EnemyOne';
        this.app = new App();
        this.helper = new Helper();
        this.scene = this.app.scene;
        this.world = this.app.world;
        this.time = this.app.time;
        this.camera = this.app.camera;
        this.resources = this.app.resources;
        this.startPosition = startPosition;
        this.rocket = this.world.rocket;
        this.time = this.app.time;
        this.enemySpawner = this.app.enemySpawner;
        this.rocketGroup = this.app.world.rocketGroup;

        //cache
        this.instance;
        // higher is less for the fire rate
        this.fireRate = 7;
        this.enemySpeed = (this.helper.getRandomInt(15, 25) * 0.00005);

        // bullet stuff
        this.bulletArray = [];
        this.bulletPoolAmount = 9;
        this.bulletCount = 0;
        this.bulletSpeed = 0.04;

        this.raycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(), 0, 0.4);
        this.raycaster.camera = this.camera.instance;
        this.direction = new THREE.Vector3();

        for (let i = 0; i < this.bulletPoolAmount; i++) {
            this.bulletArray.push(new Bullet('#FF0A10', this.className));
        }

        //get resources
        this.resource = this.resources.items['ufoModel'];

        this.copy = this.resource.clone;

        this.start();
    }

    start()
    {
        this.setModel();
        this.instance = this.instance.clone();

        
        this.instance.children[0].name = 'enemyOne';
        this.instance.scale.set(0.3, 0.3, 0.3);
        this.instance.rotation.x = Math.PI * 0.25;
        this.setTextures();
        this.setPosition(this.startPosition);
        this.instance.children[0].active = false;
        this.instance.children[0].setPositionFunction = (position) => {this.setPosition(position)};
        this.instanceCube.setPositionFunction = (position) => {this.setPosition(position)};
    }

    setModel()
    {
        this.instance = this.resource ? this.resource.scene : this.createCube();
        this.instanceCube = this.createCube();
        this.instanceCube = this.instanceCube.clone();
        this.instanceCube.visible = false;
        this.instanceCube.scale.set(3,3,6);
        this.instanceCube.name = 'EnemyOneCube';
        this.instance.userData.name = 'EnemyOneCube';
        this.instanceCube.material.opacity = 0;
        this.instanceCube.material.visible = false;
    }

    setTextures()
    {
        this.instance.traverse((c) =>
        {
            if(c instanceof THREE.Mesh)
            {
                if(c.name === 'enemyOne')
                {
                    const materialShip = new THREE.MeshPhongMaterial({
                        transparent: false
                    });
                    c.material = materialShip;
                    const texture = this.resources.items['ufoTexture'];
                    const normal = this.resources.items['ufoNormal'];
                    texture.encoding = THREE.sRGBEncoding;
                    materialShip.normalMap = normal;
                    materialShip.map = texture;
                    materialShip.shininess = 33.3;
                    materialShip.emissive = new THREE.Color('black');
                    materialShip.specular = new THREE.Color('#242424');
                    texture.flipY = false;
                    texture.generateMipmaps = false;
                    texture.minFilter = THREE.NearestFilter;
                    c.castShadow = true;
                    materialShip.transparent = true;
                }
            }
        })
    }

    createCube()
    {
        return new THREE.Mesh(
        new THREE.BoxBufferGeometry(0.3,0.3,0.3),
        new THREE.MeshBasicMaterial()
        )
    }

    setPosition(vector3Position)
    {
        const position = new THREE.Vector3(vector3Position.x, vector3Position.y, vector3Position.z);
        this.instance.position.set(position.x, position.y, position.z);
        this.instanceCube.position.set(position.x, position.y, position.z);

    }

    destroyAllBullets()
    {
        this.bulletArray.forEach((b) => {
            b.remove();
        })
    }

    fire() 
    {
        if(!this.audioManager)
        {
            this.audioManager = this.app.world.audioManager;
        }
        if(this.audioManager.audio['enemyLaser'].file.isPlaying)
        {
            this.audioManager.audio['enemyLaser'].file.pause();
            this.audioManager.audio['enemyLaser'].file.stop();
        }
        // this.audioManager.audio['bass'].file.loop = true;
        this.audioManager.audio['enemyLaser'].file.setVolume(0.65);
        this.audioManager.audio['enemyLaser'].file.play();

        const currentBullet = this.bulletArray[this.bulletCount];
        this.bulletCount++;
        currentBullet.setBulletPosition(this.instance.position);
        currentBullet.setSpeed(this.bulletSpeed + this.enemySpeed);
        // const direction = new THREE.Vector3(this.direction.x, this.direction.y, this.direction.z);
        const direction = new THREE.Vector3(
            this.direction.x + (Math.random()) - 0.5, 
            this.direction.y + (Math.random()) - 0.5, 
            this.direction.z
            );
        const target = new THREE.Vector3(this.rocket.group.position.x, this.rocket.group.position.y, this.rocket.group.position.z);
        currentBullet.setVelocity(direction, target);
        if(this.bulletCount >= this.bulletPoolAmount)
        {
            this.bulletCount = 0;
        }
    }

    target(position)
    {
        return position ? position : this.rocketGroup.position;
    }

    update()
    {
        this.instance.rotation.y = this.time.elapsed * 0.0008;
        this.instance.rotation.x = (Math.sin(this.time.elapsed * 0.5) * 0.01);
        this.instanceCube.position.set(this.instance.position.x, this.instance.position.y, this.instance.position.z);

        if(this.rocket.rocketDead === true)
        {
            if(this.instance.children[0].originPosition)
            {
                const pos = new THREE.Vector3(this.instance.children[0].originPosition.x, this.instance.children[0].originPosition.y, this.instance.children[0].originPosition.z);
                this.instance.position.lerp(pos, (this.enemySpeed * this.time.delta * 0.1));
            } 
            if(this.instance.children[0].originPosition === false)
            {
                const pos = this.helper.getRandomVector3();
                this.instance.position.lerp(pos, (this.enemySpeed * this.time.delta * 0.1));
            } 
            return;
        }

        if(this.rocket.rocketDead === false && this.target())
        {
            this.instance.position.lerp(this.target(), (this.enemySpeed * this.time.delta * 0.1));
        }

        if(this.rocket.rocketDead === false && this.time.elapsedSeconds % this.fireRate === 0)
        {
            this.fire();
        }

        this.raycaster.set(this.instance.position,  this.direction);
        const intersects = this.raycaster.intersectObjects( this.scene.children[9].children );

        if(intersects.length > 0)
        {
            intersects.forEach((x) => {
                x.object.traverse((c) => {
    
                    if(c instanceof THREE.Mesh) 
                    {
                        if(c.name === "enemyOne" && this.instance.id === c.id)
                        {
                            this.enemySpeed *= 0.1;
                        }
                        else
                        {
                            if(this.enemySpeed < (25 * 0.00005))
                            {
                                this.enemySpeed *= 4;
                            }
                        }
                        if(c.name === "rocket")
                        {
                            this.fire();
                        }
                    }
                })
            })
        }

        this.direction.subVectors(this.target(), this.instance.position);
    }
}