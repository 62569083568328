import * as THREE from 'three';

let instance = null;

export default class AudioListener
{

    constructor()
    {
        if(instance)
        {
            return this;
        }

        instance = this;
        
        this.instance = new THREE.AudioListener();

        //cache
        this.on = document.getElementById('on');
        this.off = document.getElementById('off');

        if(!localStorage.getItem('audio'))
        {
            localStorage.setItem('audio', 'false');
        }

        if(localStorage.getItem('audio') === 'false')
        {
            this.audioOff();
        }
        else{
            this.audioOn();
        }

        const muteButton = document.getElementById('mute-button');

        muteButton.addEventListener('click', (e) => {
            if(localStorage.getItem('audio') === 'false')
            {
                this.audioOn();
            }
            else
            {
                this.audioOff();
            }
        })

    }

    setPosition(position)
    {
        this.instance.position.set(position.x, position.y, position.z);
    }

    audioOn()
    {
        this.instance.setMasterVolume(1);
        localStorage.setItem('audio', true);

        this.on.style.cssText = 'display: inherit';
        this.on.style.cssText = 'color: white';
        this.off.style.cssText = 'display: none';
    }

    audioOff()
    {
        this.instance.setMasterVolume(0);
        localStorage.setItem('audio', false);

        this.on.style.cssText = 'display: none';
        this.off.style.cssText = 'display: inherit';
        this.off.style.cssText = 'color: white';

    }

    setVolume(value)
    {
        this.instance.setMasterVolume(value);
    }

    setFilter(value)
    {
        this.instance.setFilter(value)
    }
} 