import * as THREE from 'three';
import App from "../App";
import AudioListener from '../AudioListener';

export default class AudioManager
{
    constructor()
    {

        this.app = new App();
        this.scene = this.app.scene;
        this.listener = new AudioListener();
        this.resources = this.app.resources;
        this.items = this.resources.items;
        this.time = this.app.time;
        this.audio = {};
        this.value = 0.0

        Object.keys(this.items).forEach((item) => {
                if(this.items[item] instanceof AudioBuffer)
                {
                this.audio[item] = {
                    buffer: this.items[item],
                    file: new THREE.Audio(this.listener.instance)
                }
                this.scene.add(this.audio[item].file);

                this.audio[item].file.setBuffer(this.audio[item].buffer);
                if(this.audio[item].buffer.style === "loop")
                {
                    this.audio[item].file.loop = true;
                }

                if(this.audio[item].buffer.groupId === '2')
                {   
                    setTimeout(() => {
                        switch(this.audio[item].buffer.name)
                        {
                            case 'lightTune':
                                this.audio[item].file.setVolume(this.value);
                                this.audio[item].file.play();
                                break; 
                        }
                    }, 2000);
                }
                }
        })
    }
}