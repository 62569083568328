import * as THREE from 'three';
import App from '../../App';
import Helper from '../../Utils/Helper';
import EventEmitter from '../../Utils/EventEmitter';

export default class ScoreText extends EventEmitter
{
    constructor() 
    {
        super();

        // this.fontLoader = new FontLoader();
        this.app = new App();
        this.helper = new Helper();
        this.scene = this.app.scene;
        this.sizes = this.app.sizes;
        this.camera = this.app.camera;
        this.scrollManager = this.app.scrollManager;
        this.page = this.app.scrollPageNumbers.page;

        // cache
        this.padding = new THREE.Vector2(this.sizes.width * 0.180, 50);
        this.score = 1000000;
        this.scoreWaitingRoom = 1000000;
        this.levelUpAmount = 1000750;
        this.scoreToNextLevelMultiplier = 2000;
        this.level = 1;
        this.newHighScore = false;
        this.recheckHiScore = true;


        this.setup();
    }

    setup() 
    {
        this.fontElement = document.querySelector('.score');
        this.fontElement.innerHTML = this.score.toString().substring(1);
        this.fontElement2 = document.querySelector('.hi-score');
        this.fontElement2.innerHTML = '';
    }

    add(number)
    {
        this.scoreWaitingRoom += number;
    }

    update() 
    {
            if(this.recheckHiScore && localStorage.getItem('score'))
            {
                if(parseInt(localStorage.getItem('score')) !== parseInt(this.fontElement2))
                {
                    this.recheckHiScore = false;
                    this.fontElement2.innerHTML = 'HI-SCORE 0' + localStorage.getItem('score').substring(1);
                }
            }

            if(!this.app.world.rocket.rocketDead && this.app.scrollPageNumbers.page === 1 || this.app.scrollPageNumbers.page === 18)
            {
                // this.fontElement.innerHTML = "000000";

                if(this.scoreWaitingRoom !== this.score)
                {
                    //early levelup scheme
                    this.level < 3 ? this.scoreToNextLevelMultiplier = 750 : this.scoreToNextLevelMultiplier = 2000;

                    //updating score
                    this.score = this.scoreWaitingRoom;
                    this.enemyOneMaster = this.app.world.enemyOneMaster;

                    if(this.score > this.levelUpAmount)
                    {
                        this.levelUpAmount += this.scoreToNextLevelMultiplier;
                        this.enemyOneMaster.addEnemy();
                        this.level++;
                    }

                    this.fontElement.innerHTML = `SCORE ${this.score.toString().substring(1)}`;

                    if(this.score % 1000 === 0)
                    {
                        if(!this.audioManager)
                        {
                            this.audioManager = this.app.world.audioManager;
                        }
                        if(this.audioManager.audio['score'].file.isPlaying)
                        {
                            this.audioManager.audio['score'].file.pause();
                            this.audioManager.audio['score'].file.stop();
                        }
                        this.audioManager.audio['score'].file.play();

                        this.enemyOneMaster.enemyOneArrayAmount = 1;
                    }

                    this.recheckHiScore = true;
                }
        }
        else
        {
            if(this.fontElement.innerHTML !== "" || this.fontElement2.innerHTML !== "")
            {
                this.fontElement.innerHTML = "";
                this.fontElement2.innerHTML = "";
            }
        }
    }
}