import * as THREE from 'three';

import Debug from './Utils/Debug.js';
import Sizes from './Utils/Sizes.js';
import Time from './Utils/Time.js';
import Camera from './Camera.js';
import Renderer from './Renderer.js';
import World from './World/World.js';
import Resources from './Utils/Resources.js';
import ScrollManager from './Utils/ScrollManager.js';

import sources from './sources.js';
import ScrollPageNumbers from './Utils/ScrollPageNumbers.js';
import ScrollTimeline from './Utils/ScrollTimeline.js';
import Mouse from './Utils/Mouse.js';
import EnemySpawner from './EnemySpawner.js';


let instance = null

export default class App
{
    constructor(_canvas)
    {
        window.onbeforeunload = () => { window.scroll(0, 0) };

        // Singleton
        if(instance)
        {
            return instance;
        }
        instance = this

        // this.LoadingPlane = await this.loadingState();

        
        // Global access
        window.app = this;


        // Options
        this.canvas = _canvas

        // Setup
        this.scrollManager = new ScrollManager();
        this.debug = new Debug();
        this.sizes = new Sizes();
        this.time = new Time();
        this.scene = new THREE.Scene();
        this.resources = new Resources(sources);
        this.cameraGroup = new THREE.Group();
        this.scene.add(this.cameraGroup);
        this.camera = new Camera(this.cameraGroup);
        this.mouse = new Mouse();
        this.renderer = new Renderer();
        this.scrollPageNumbers = new ScrollPageNumbers();
        this.enemySpawner = new EnemySpawner();
        this.world = new World();
        this.scrollTimeline = new ScrollTimeline();



        // Resize event
        this.sizes.on('resize', () =>
        {
            this.resize();
        })

        // Time tick event
        this.time.on('tick', () =>
        {
            this.update();
        })
    }

    // async loadingState()
    // {
    //     return new LoadingPlane();
    // }

    resize()
    {
        this.camera.resize();
        this.renderer.resize();
    }

    update()
    {
        this.camera.update();
        this.world.update();
        this.renderer.update();
        this.enemySpawner.update();
        // this.mouse.update();
    }

    destroy()
    {
        this.sizes.off('resize')
        this.time.off('tick')

        // Traverse the whole scene
        this.scene.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()

        if(this.debug.active)
            this.debug.ui.destroy()
    }
}