import * as THREE from 'three';
import App from "../App"
import * as dat from 'lil-gui'
import galaxyVertexShader from '../Shaders/galaxy/vertex.glsl'
import galaxyFragmentShader from '../Shaders/galaxy/fragment.glsl'

export default class Galaxy 
{
    constructor(group) 
    {
        this.app = new App();
        this.scene = this.app.scene;
        this.debug = this.app.debug;
        this.group = group;
        this.time = this.app.time;
        this.renderer = this.app.renderer;

        ///cache
        // this.scrollLastFrame = 0;

        // Scene
        this.scene = new THREE.Scene()

        /**
         * Galaxy
         */
        this.parameters = {}
        this.parameters.count = 15000;
        this.parameters.size = 0.005
        this.parameters.radius = 5.31
        this.parameters.branches = 20
        this.parameters.spin = 1
        this.parameters.randomness = 0.494
        this.parameters.randomnessPower = 3.548
        this.parameters.insideColor = '#ff6030'
        this.parameters.outsideColor = '#1b3984'

        this.geometry = null
        this.material = null
        this.instance = null

        this.generateGalaxy();

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('galaxy');

            this.debugFolder.add(this.parameters, 'count').min(100).max(1000000).step(100).onFinishChange(this.generateGalaxy)
            this.debugFolder.add(this.parameters, 'radius').min(0.01).max(20).step(0.01).onFinishChange(this.generateGalaxy)
            this.debugFolder.add(this.parameters, 'branches').min(2).max(20).step(1).onFinishChange(this.generateGalaxy)
            this.debugFolder.add(this.parameters, 'randomness').min(0).max(2).step(0.001).onFinishChange(this.generateGalaxy)
            this.debugFolder.add(this.parameters, 'randomnessPower').min(1).max(10).step(0.001).onFinishChange(this.generateGalaxy)
            this.debugFolder.addColor(this.parameters, 'insideColor').onFinishChange(this.generateGalaxy)
            this.debugFolder.addColor(this.parameters, 'outsideColor').onFinishChange(this.generateGalaxy)
        }
    }

    generateGalaxy = () =>
    {
        if(this.instance !== null)
        {
            this.geometry.dispose()
            this.material.dispose()
            this.group.remove(this.instance)
        }

        /**
         * Geometry
         */
        this.geometry = new THREE.BufferGeometry()

        const positions = new Float32Array(this.parameters.count * 3)
        const randomness = new Float32Array(this.parameters.count * 3)
        const colors = new Float32Array(this.parameters.count * 3)
        const scales = new Float32Array(this.parameters.count * 1)

        const insideColor = new THREE.Color(this.parameters.insideColor)
        const outsideColor = new THREE.Color(this.parameters.outsideColor)

        for(let i = 0; i < this.parameters.count; i++)
        {
            const i3 = i * 3

            // Position
            const radius = Math.random() * this.parameters.radius

            const branchAngle = (i % this.parameters.branches) / this.parameters.branches * Math.PI * 2

            const randomX = Math.pow(Math.random(), this.parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * this.parameters.randomness * radius
            const randomY = Math.pow(Math.random(), this.parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * this.parameters.randomness * radius
            const randomZ = Math.pow(Math.random(), this.parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * this.parameters.randomness * radius

            positions[i3    ] = Math.cos(branchAngle) * radius
            positions[i3 + 1] = 0
            positions[i3 + 2] = Math.sin(branchAngle) * radius
        
            randomness[i3    ] = randomX
            randomness[i3 + 1] = randomY
            randomness[i3 + 2] = randomZ

            // Color
            const mixedColor = insideColor.clone()
            mixedColor.lerp(outsideColor, radius / this.parameters.radius)

            colors[i3    ] = mixedColor.r
            colors[i3 + 1] = mixedColor.g
            colors[i3 + 2] = mixedColor.b

            // Scale
            scales[i] = Math.random()
        }

        this.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        this.geometry.setAttribute('aRandomness', new THREE.BufferAttribute(randomness, 3))
        this.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
        this.geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))

        /**
         * Material
         */
        this.material = new THREE.ShaderMaterial({
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            uniforms:
            {
                uTime: { value: 0 },
                uSize: { value: 30 * this.renderer.instance.getPixelRatio() }
            },    
            vertexShader: galaxyVertexShader,
            fragmentShader: galaxyFragmentShader
        })

        /**
         * Points
         */
        this.instance = new THREE.Points(this.geometry, this.material);
        this.instance.name = 'galaxy';
        this.group.add(this.instance);
        // this.group.rotation.x = Math.PI * 0.15;
    }

    update()
    {
        this.material.uniforms.uTime.value = (this.time.elapsed) * 0.00015;
    }

}