import * as THREE from 'three';
import App from '../App.js';
import EventEmitter from '../Utils/EventEmitter.js';
import Helper from '../Utils/Helper.js';
import Bullet from './Bullet.js';
import Explosion from './Explosion.js';

export default class Rocket extends EventEmitter
{
    constructor(model, group, gameSceneGroup)
    {

        super();

        //helper
        this.helper = new Helper();

        this.app = new App();
        this.className = 'Rocket';
        this.scene = this.app.scene;
        this.resources = this.app.resources;
        this.debug = this.app.debug;
        this.model = model;
        this.group = group;
        this.gameScene = gameSceneGroup;
        this.camera = this.app.camera;
        this.mouse = this.app.mouse;
        this.time = this.app.time;
        this.scrollManager = this.app.scrollManager;
        this.audioManager = this.app.world.audioManager;
        // this.test = this.app.world.heavyBucketMoon.instance;

        //cache
        this.instance;
        this.shipSpeed = 0.034;
        this.rocketDead = true;
        this.rocketDeadinTimeout = false;
        this.rocketRespawnTime = 1000; //m/s
        this.stopBeforePointerAmount = 0.5;
        this.shipParked = false;
        this.storedDirection = new THREE.Vector3();
        this.page = 1;
        this.didILeaveThePageWhileRocketWasStillAlive = false;
        this.timeRocketSoundPlayed = this.time.elapsed;

        //rocket movement
        this.targetPositionVector3 = new THREE.Vector3(-3.0, 0, 0);
        this.currentPositionVector3 = new THREE.Vector3(-1.5, 0, 0);
        this.defaultPosition = new THREE.Vector3(-10, 0, 0);
        // this.localPosition = new THREE.Matrix4();

        // // Debug
        // if(this.debug.active)
        // {
        //     this.debugFolder = this.debug.ui.addFolder('moon');  
        // }
        this.axesHelper = new THREE.AxesHelper();

        // Resource
        this.resource = this.resources.items[model];

        this.setModel();
        this.runMouseDetect();
        this.runTouchDetect();
        this.runMouseClickDetect();
        this.runScrollDetect();

        this.raycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(), 0, 0.4);
        this.raycaster.camera = this.camera.instance;
        this.direction = new THREE.Vector3(0,0,0);

        // bullet stuff
        this.bulletArray = [];
        this.bulletPoolAmount = 6;
        this.bulletCount = 0;
        this.bulletSpeed = 0.3;
        this.bulletSpread = 0.1;

        for (let i = 0; i < this.bulletPoolAmount; i++) {
            this.bulletArray.push(new Bullet('#189DFF', this.className, this.gameScene));
        }

        // explosion stuff
        this.explosionArray = [];
        this.explosionPoolAmount = 3;
        this.explosionCount = 0;

        for (let i = 0; i < this.explosionPoolAmount; i++) {
            this.explosionArray.push(new Explosion());
        }

    }

    onPageOneOrEighteen()
    {
        return this.app.scrollPageNumbers.page === 1 || this.app.scrollPageNumbers.page === 18 ? true : false;
    }


    setModel()
    {
        this.instance = this.resource ? this.resource.scene : this.createCube();
        this.instance.scale.set(0.025, 0.025, 0.025);
        this.instance.rotation.x = Math.PI * 0.5;
        this.instanceCube = this.createCube();
        this.instanceCube = this.instanceCube.clone();
        this.instanceCube.visible = false;
        this.instanceCube.scale.set(1,1,2.7);
        this.instanceCube.name = 'RocketCube';
        this.instance.userData.name = 'RocketCube';
        this.instanceCube.position.z += 0.35;

        this.instance.traverse((c) =>
        {
            if(c instanceof THREE.Mesh)
            {
                if(c.name === 'Rocket') 
                {
                    const materialShip = new THREE.MeshPhongMaterial({
                        transparent: false
                    });
                    c.material = materialShip;
                    const texture = this.resources.items['rocketTexture'];
                    const ao = this.resources.items['rocketAo'];
                    const normal = this.resources.items['rocketNormal'];
                    texture.encoding = THREE.sRGBEncoding;
                    materialShip.map = texture;
                    materialShip.aoMap = ao;
                    materialShip.normalMap = normal;
                    materialShip.shininess = 33.3;
                    materialShip.emissive = new THREE.Color('black');
                    materialShip.specular = new THREE.Color('#242424');
                    texture.flipY = false;
                    texture.generateMipmaps = false;
                    texture.minFilter = THREE.NearestFilter;
                    c.castShadow = true;
                    materialShip.transparent = true;
                }
            }
        })
        this.resetRocketPosition();
        // this.group.add(this.instance);
        // this.group.add(this.instanceCube);
    }

    resetRocketPosition()
    {
        this.group.position.set(this.defaultPosition.x, this.defaultPosition.y, this.defaultPosition.z);
    }

    createCube() 
    {
        return new THREE.Mesh(
            new THREE.BoxBufferGeometry(0.1,0.2,0.2),
            new THREE.MeshBasicMaterial()
        )
    }

    runMouseDetect() 
    {

        this.mouse.on('mouse-move', () => 
            {
                if(this.onPageOneOrEighteen())
                {
                    if(this.didILeaveThePageWhileRocketWasStillAlive)
                    {
                        this.didILeaveThePageWhileRocketWasStillAlive === false;
                        this.rocketDead = false;
                    }
                    this.targetPositionVector3 = new THREE.Vector3(this.mouse.positionInThreeSpace.x, this.mouse.positionInThreeSpace.y, this.mouse.positionInThreeSpace.z);
                    return;
                }
                // if(!this.onPageOneOrEighteen())
                // {
                //     this.rocketDead = true;
                // }
            }
        )
    }

    runTouchDetect()
    {
        // this.mouse.on('touch-move', () => 
        //     {
        //         if(this.onPageOneOrEighteen())
        //         {
        //             if(this.didILeaveThePageWhileRocketWasStillAlive)
        //             {
        //                 this.rocketDead = false;
        //             }
        //             // this.rocketDeadinTimeout = false;
        //             this.targetPositionVector3 = new THREE.Vector3(this.mouse.positionInThreeSpaceTouch.x, this.mouse.positionInThreeSpaceTouch.y, this.mouse.positionInThreeSpaceTouch.z);
        //         }
        //         else
        //         {
        //             this.rocketDead = true;
        //         }
        //     }
        // )
    }

    runScrollDetect()
    {
        this.scrollManager.on('three-scroll', () => {
            this.page = this.app.scrollPageNumbers.page;

            if(this.page === 1 || this.page === 18)
            {
                if(this.didILeaveThePageWhileRocketWasStillAlive)
                {
                    if(!this.audioManager)
                    {
                        this.audioManager = this.app.world.audioManager;
                    }
            
                    this.audioManager.value = 0.5;
                    this.audioManager.audio['lightTune'].file.setVolume(this.audioManager.value);
                }
                this.rocketDeadinTimeout = false;
                this.targetPositionVector3 = new THREE.Vector3(this.mouse.positionInThreeSpace.x, this.mouse.positionInThreeSpace.y, this.mouse.positionInThreeSpace.z);
                return;
            }
            else
            {
                if(this.rocketDead === false)
                {
                    this.didILeaveThePageWhileRocketWasStillAlive = true;
                }

                this.rocketDead = true;
                this.targetPositionVector3 = new THREE.Vector3(this.defaultPosition.x + 3, this.defaultPosition.y, this.defaultPosition.z);
            }
        })
    }

    explosionImpact(position, color)
    {
        if(!this.audioManager)
        {
            this.audioManager = this.app.world.audioManager;
        }
        if(this.audioManager.audio['explosion'].file.isPlaying)
        {
            this.audioManager.audio['explosion'].file.pause();
            this.audioManager.audio['explosion'].file.stop();
        }
        this.audioManager.audio['explosion'].file.play();


        const currentExplsion = this.explosionArray[this.explosionCount];
        currentExplsion.setColor(color);
        this.explosionCount++;
        currentExplsion.setPosition(position);
        if(this.explosionCount >= this.explosionPoolAmount)
        {
            this.explosionCount = 0;
        }
    }

    runMouseClickDetect()
    {
        this.mouse.on('mouse-click', () => 
        {

            if(this.onPageOneOrEighteen())
            {
                if(this.rocketDeadinTimeout)
                {
                    return;
                }
                if(this.rocketDead)
                {
                    this.respawnRocketLogic();
                    return;
                }
                    if(!this.audioManager)
                    {
                        this.audioManager = this.app.world.audioManager;
                    }
                    if(this.audioManager.audio['playerLaser'].file.isPlaying)
                    {
                        this.audioManager.audio['playerLaser'].file.pause();
                        this.audioManager.audio['playerLaser'].file.stop();
                    }
                    // this.audioManager.audio['bass'].file.loop = true;
                    this.audioManager.audio['playerLaser'].file.setVolume(0.58);
                    this.audioManager.audio['playerLaser'].file.play();

                    const currentBullet1 = this.bulletArray[this.bulletCount];
                    this.bulletCount++;
                    currentBullet1.setBulletPosition(this.group.position);
                    currentBullet1.setSpeed(this.bulletSpeed);

                    
                    const xAbsolute = (this.direction.clone().x)
                    const yAbsolute = (this.direction.clone().y)
                    // console.log('x abs ', xAbsolute);
                    // console.log('y abs ', yAbsolute);

                    const s = 1 - (Math.abs(xAbsolute - yAbsolute));

                    // const xMuliplier = 1 - xAbsolute;
                    // const yMuliplier = 1 - yAbsolute;

                    const d1 = new THREE.Vector3(
                        this.direction.x - this.bulletSpread * s,
                        this.direction.y - this.bulletSpread * s,
                        this.direction.z
                        );
                    const m1 = new THREE.Vector3(this.targetPositionVector3.x, this.targetPositionVector3.y, this.targetPositionVector3.z);
                    currentBullet1.setVelocity(d1, m1);
                    if(this.bulletCount >= this.bulletPoolAmount)
                    {
                        this.bulletCount = 0;
                    }
    
                    const currentBullet2 = this.bulletArray[this.bulletCount];
                    this.bulletCount++;
                    currentBullet2.setBulletPosition(this.group.position);
                    currentBullet2.setSpeed(this.bulletSpeed);
                    const d2 = new THREE.Vector3(
                        this.direction.x + this.bulletSpread * s,
                        this.direction.y + this.bulletSpread * s,
                        this.direction.z
                        );
                    const m2 = new THREE.Vector3(this.targetPositionVector3.x, this.targetPositionVector3.y, this.targetPositionVector3.z);
                    currentBullet2.setVelocity(d2, m2);
                    if(this.bulletCount >= this.bulletPoolAmount)
                    {
                        this.bulletCount = 0;
                    }
    
                    const currentBullet3 = this.bulletArray[this.bulletCount];
                    this.bulletCount++;
                    currentBullet3.setBulletPosition(this.group.position);
                    currentBullet3.setSpeed(this.bulletSpeed);
                    const d3 = new THREE.Vector3(this.direction.x, this.direction.y, this.direction.z);
                    const m3 = new THREE.Vector3(this.targetPositionVector3.x, this.targetPositionVector3.y, this.targetPositionVector3.z);
                    currentBullet3.setVelocity(d3, m3);
                    if(this.bulletCount >= this.bulletPoolAmount)
                    {
                        this.bulletCount = 0;
                    }
            }
            else if(this.rocketDeadinTimeout === false)
            {
                this.rocketDead = true;
            }
        })
    }

    respawnRocketLogic()
    {
        if(this.rocketDeadinTimeout)
        {
            return;
        }
        if(!this.audioManager)
        {
            this.audioManager = this.app.world.audioManager;
        }

        this.audioManager.value = 0.5;
        this.audioManager.audio['lightTune'].file.setVolume(this.audioManager.value);

        this.rocketDead = false;
        
        if(this.didILeaveThePageWhileRocketWasStillAlive === false)
        {
            this.rocketDeadinTimeout = true;

            setTimeout(() => {
                // get rid of text
                if(!this.heavyBucketMoonText)
                {
                    this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                }
                this.heavyBucketMoonText.textFuckOff();

                this.rocketDeadinTimeout = false;
                this.resetRocketPosition();
                this.group.add(this.instance);
                this.group.add(this.instanceCube);
            }, this.rocketRespawnTime);
        }
        else
        {
            if(this.onPageOneOrEighteen())
            {
                this.didILeaveThePageWhileRocketWasStillAlive = false;
            }
        }
    }

    update() 
    {
        // console.log(this.rocketDead);
        this.instance.rotation.y = this.time.elapsed * 0.0008;
        
        let target = new THREE.Vector3(this.targetPositionVector3.x, this.targetPositionVector3.y, this.targetPositionVector3.z);
        let groupPostionCopy = new THREE.Vector3(this.group.position.x, this.group.position.y, this.group.position.z);

        if(this.shipParked === false)
        {
            this.direction.subVectors( target, groupPostionCopy);
            this.storedDirection = new THREE.Vector3(this.direction.x, this.direction.y, this.direction.z);
        }
        const distanceBetweenThisRocketAndThePointer = this.helper.magnitude(this.direction);
        // console.log(distanceBetweenThisRocketAndThePointer);
        this.direction.normalize()

        if(this.rocketDead === false || (this.app.scrollPageNumbers.page !== 1 || this.app.scrollPageNumbers.page !== 18))
        {
            if(distanceBetweenThisRocketAndThePointer > this.stopBeforePointerAmount)
            {
                this.group.position.lerp(this.targetPositionVector3, (this.shipSpeed * this.time.delta * 0.1));
                this.shipParked = false;
            }
            else if(distanceBetweenThisRocketAndThePointer < this.stopBeforePointerAmount)
            {
                this.shipParked = true;
                // float about a bit
                const sinX = Math.sin(this.time.elapsed);
                const cosY = Math.cos(this.time.elapsed);
                this.group.position.x -= Math.round(this.storedDirection.x) * this.time.delta * this.shipSpeed * sinX;
                this.group.position.y -= Math.round(this.storedDirection.y) * this.time.delta * this.shipSpeed * cosY;
                this.group.position.z -= Math.round(this.storedDirection.z) * this.time.delta * this.shipSpeed;
            }
        }


        if(this.rocketDead === false && (distanceBetweenThisRocketAndThePointer > 2 && this.time.elapsed > (this.timeRocketSoundPlayed + 500)))
        {
            this.timeRocketSoundPlayed = this.time.elapsed;

            if(!this.audioManager)
            {
                this.audioManager = this.app.world.audioManager;
            }
            if(this.audioManager.audio['rocket'].file.isPlaying)
            {
                this.audioManager.audio['rocket'].file.pause();
                this.audioManager.audio['rocket'].file.stop();
                this.audioManager.audio['rocket'].file.play();
            }
            else
            {
                this.audioManager.audio['rocket'].file.play();
            }

        }

        this.group.lookAt(this.targetPositionVector3);
        this.currentPositionVector3 = this.group.position;

        this.raycaster.set(this.group.position,  this.direction);
        const intersects = this.raycaster.intersectObjects( this.scene.children[9].children );

        if(intersects.length > 0) 
        {
            intersects.forEach((x) => {
                x.object.traverse((c) => {
                    if(c instanceof THREE.Mesh) 
                    {
                        // console.log(c);
                        // if(c.name === "LowPolyLogoMoon")
                        // {
                        //     const np = new THREE.Vector3(this.instance.position.x, this.instance.position.y, this.instance.position.z)
                        //     this.explosionImpact(np, 'blue');
                        //     if(!this.scoreText)
                        //     {
                        //         this.scoreText = this.app.world.scoreText;
                        //     }
                        //     this.scoreText.scoreWaitingRoom = 1000000;
                        //     this.scoreText.level = 1;
                        //     this.rocketDead = true;
                        //     this.didILeaveThePageWhileRocketWasStillAlive = false;
                        //     this.group.remove(this.instance);
                        // }
                        if(this.rocketDead === false && c.name === "EnemyOneCube")
                        {
                            // console.log(c);
                            if(!this.scoreText)
                            {
                                this.scoreText = this.app.world.scoreText;
                            }

                            // store in locale storage
                            if(this.scoreText.newHighScore === false && (!localStorage.getItem('score') || parseInt(localStorage.getItem('score')) < this.scoreText.score))
                            {
                                localStorage.setItem('score', this.scoreText.score);
                                this.scoreText.newHighScore = true;
                            }

                            this.scoreText.scoreWaitingRoom = 1000000;
                            this.scoreText.level = 1;
                            this.rocketDead = true;
                            this.didILeaveThePageWhileRocketWasStillAlive = false;
                            this.group.remove(this.instance);
                            this.group.remove(this.instanceCube);
                            const np = new THREE.Vector3(c.position.x, c.position.y, c.position.z)
                            this.explosionImpact(np, 'blue');
                        }
                    }
                })
            })
        }

        this.bulletArray.forEach((bullet) => {
            bullet.update();
        })


        this.explosionArray.forEach((e) => {
            e.update();
        })

    }
}