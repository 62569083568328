import * as THREE from 'three';
import App from "../App";
import explosionVertexShader from '../Shaders/explosion/vertex.glsl'
import explosionFragmentShader from '../Shaders/explosion/fragment.glsl'

export default class Explosion {
    constructor()
    {

        this.app = new App();
        this.scene = this.app.scene;
        this.time = this.app.time;
        this.resources = this.app.resources; 
        this.items = this.resources.items;   
        this.scene = this.app.scene;

        //cache
        this.position = new THREE.Vector3(0,0,0);
        this.instance;
        this.uniforms = {};
        this.geometry;
        this.noOfFrames = 34;
        this.spriteArray = [this.noOfFrames];
        this.frameCount = 0;
        this.animationGroup = new THREE.Group();

        this.start();
    }

    start()
    {
        this.uniforms.uBend = { value: this.time.elapsed }

        this.geometry = new THREE.SphereBufferGeometry(1,16,16);

        this.instance = new THREE.Mesh(
            this.geometry,
            new THREE.ShaderMaterial({
                uniforms: this.uniforms,
                vertexShader: explosionVertexShader,
                fragmentShader: explosionFragmentShader,
                side: THREE.DoubleSide,
                transparent: true
            })
        )
        this.instance.scale.set(1,1,1);
        this.instance.name = 'explosion';

        this.loadSprites();
    }

    loadSprites()
    {
        for (let i = 0; i < this.noOfFrames; i++) {
            const texture = this.items[`explosionFrame${(i + 1).toString()}`];
            texture.encoding = THREE.sRGBEncoding;
            const material = new THREE.SpriteMaterial( 
                { 
                    map: texture,
                } 
            );
            const sprite = new THREE.Sprite( material );
            sprite.name = `explosionFrame${(i + 1).toString()}`;
            this.spriteArray[i] = sprite;
        }
    }

    createCube() 
    {
        return new THREE.Mesh(
            new THREE.BoxBufferGeometry(1,1,1),
            new THREE.MeshBasicMaterial()
        )
    }

    setPosition(position) 
    {
        this.instance.position.set(position.x, position.y, position.z);
        this.animationGroup.position.set(position.x, position.y + 0.1, position.z);
        // this.scene.add(this.instance);
        this.scene.add(this.animationGroup);

        setTimeout(() => {
            this.scene.remove(this.instance);
            this.scene.remove(this.animationGroup);
        }, 1000);
    }

    setColor(color)
    {
        const c = new THREE.Color(color);
        const array = new Float32Array(3);
        array[0] = c.r;
        array[1] = c.g;
        array[2] = c.b;

        this.uniforms.colorR = { value:  array[0]}
        this.uniforms.colorG = { value:  array[1]}
        this.uniforms.colorB = { value:  array[2]}

    }

    update()
    {
    // Update the uniforms
		this.uniforms.uBend.value = this.time.elapsed;

        if(this.frameCount != 0)
        {
            this.animationGroup.remove(this.spriteArray[this.frameCount - 1])
        }
        else
        {
            this.animationGroup.remove(this.spriteArray[this.noOfFrames - 1])
        }

        // this.spriteArray[this.frameCount].position.set(1,1,-20);
        this.animationGroup.add(this.spriteArray[this.frameCount]);

        this.frameCount ++;


        if(this.frameCount > 33)
        {
            this.frameCount = 0;
        }

    }
}