export default [
    {
        name: 'explosionFrame1',
        type: 'texture',
        path: 'sprites/explosion/fireball0001.webp',
    },
    {
        name: 'explosionFrame2',
        type: 'texture',
        path: 'sprites/explosion/fireball0002.webp',
    },
    {
        name: 'explosionFrame3',
        type: 'texture',
        path: 'sprites/explosion/fireball0003.webp',
    },
    {
        name: 'explosionFrame4',
        type: 'texture',
        path: 'sprites/explosion/fireball0004.webp',
    },
    {
        name: 'explosionFrame5',
        type: 'texture',
        path: 'sprites/explosion/fireball0005.webp',
    },
    {
        name: 'explosionFrame6',
        type: 'texture',
        path: 'sprites/explosion/fireball0006.webp',
    },
    {
        name: 'explosionFrame7',
        type: 'texture',
        path: 'sprites/explosion/fireball0007.webp',
    },
    {
        name: 'explosionFrame8',
        type: 'texture',
        path: 'sprites/explosion/fireball0008.webp',
    },
    {
        name: 'explosionFrame9',
        type: 'texture',
        path: 'sprites/explosion/fireball0009.webp',
    },
    {
        name: 'explosionFrame10',
        type: 'texture',
        path: 'sprites/explosion/fireball0010.webp',
    },
    {
        name: 'explosionFrame11',
        type: 'texture',
        path: 'sprites/explosion/fireball0011.webp',
    },
    {
        name: 'explosionFrame12',
        type: 'texture',
        path: 'sprites/explosion/fireball0012.webp',
    },
    {
        name: 'explosionFrame13',
        type: 'texture',
        path: 'sprites/explosion/fireball0013.webp',
    },
    {
        name: 'explosionFrame14',
        type: 'texture',
        path: 'sprites/explosion/fireball0014.webp',
    },
    {
        name: 'explosionFrame15',
        type: 'texture',
        path: 'sprites/explosion/fireball0015.webp',
    },
    {
        name: 'explosionFrame16',
        type: 'texture',
        path: 'sprites/explosion/fireball0016.webp',
    },
    {
        name: 'explosionFrame17',
        type: 'texture',
        path: 'sprites/explosion/fireball0017.webp',
    },
    {
        name: 'explosionFrame18',
        type: 'texture',
        path: 'sprites/explosion/fireball0018.webp',
    },
    {
        name: 'explosionFrame19',
        type: 'texture',
        path: 'sprites/explosion/fireball0019.webp',
    },
    {
        name: 'explosionFrame20',
        type: 'texture',
        path: 'sprites/explosion/fireball0020.webp',
    },
    {
        name: 'explosionFrame21',
        type: 'texture',
        path: 'sprites/explosion/fireball0021.webp',
    },
    {
        name: 'explosionFrame22',
        type: 'texture',
        path: 'sprites/explosion/fireball0022.webp',
    },
    {
        name: 'explosionFrame23',
        type: 'texture',
        path: 'sprites/explosion/fireball0023.webp',
    },
    {
        name: 'explosionFrame24',
        type: 'texture',
        path: 'sprites/explosion/fireball0024.webp',
    },
    {
        name: 'explosionFrame25',
        type: 'texture',
        path: 'sprites/explosion/fireball0025.webp',
    },
    {
        name: 'explosionFrame26',
        type: 'texture',
        path: 'sprites/explosion/fireball0026.webp',
    },
    {
        name: 'explosionFrame27',
        type: 'texture',
        path: 'sprites/explosion/fireball0027.webp',
    },
    {
        name: 'explosionFrame28',
        type: 'texture',
        path: 'sprites/explosion/fireball0028.webp',
    },
    {
        name: 'explosionFrame29',
        type: 'texture',
        path: 'sprites/explosion/fireball0029.webp',
    },
    {
        name: 'explosionFrame30',
        type: 'texture',
        path: 'sprites/explosion/fireball0030.webp',
    },
    {
        name: 'explosionFrame31',
        type: 'texture',
        path: 'sprites/explosion/fireball0031.webp',
    },
    {
        name: 'explosionFrame32',
        type: 'texture',
        path: 'sprites/explosion/fireball0032.webp',
    },
    {
        name: 'explosionFrame33',
        type: 'texture',
        path: 'sprites/explosion/fireball0033.webp',
    },
    {
        name: 'explosionFrame34',
        type: 'texture',
        path: 'sprites/explosion/fireball0034.webp',
    },

    {
        name: 'spawn',
        type: 'audio',
        path: 'audio/spawn1.mp3',
        style: 'oneShot',
        groupId: '3'
    },
    {
        name: 'score',
        type: 'audio',
        path: 'audio/score1.mp3',
        style: 'oneShot',
        groupId: '3'
    },
    {
        name: 'rocket',
        type: 'audio',
        path: 'audio/Rocket1.mp3',
        style: 'oneShot',
        groupId: '3'
    },
    {
        name: 'explosion',
        type: 'audio',
        path: 'audio/Explosion1.mp3',
        style: 'oneShot',
        groupId: '3'
    },
    {
        name: 'playerLaser',
        type: 'audio',
        path: 'audio/PlayerLaser1.mp3',
        style: 'oneShot',
        groupId: '3'
    },
    {
        name: 'enemyLaser',
        type: 'audio',
        path: 'audio/EnemyLaser1.mp3',
        style: 'oneShot',
        groupId: '4'
    },
    // {
    //     name: 'title',
    //     type: 'audio',
    //     path: 'audio/Moon 1.1.mp3',
    //     style: 'loop',
    //     groupId: '1'
    // },
    {
        name: 'lightTune',
        type: 'audio',
        path: 'audio/LightTune1.mp3',
        style: 'loop',
        groupId: '2'
    },
    // {
    //     name: 'bass',
    //     type: 'audio',
    //     path: 'audio/MoonBass1.mp3',
    //     style: 'loop',
    //     groupId: '2'
    // },
    // {
    //     name: 'synth',
    //     type: 'audio',
    //     path: 'audio/MidTune1.mp3',
    //     style: 'loop',
    //     groupId: '2'
    // },
    // {
    //     name: 'lead',
    //     type: 'audio',
    //     path: 'audio/Lead1.mp3',
    //     style: 'constant',
    //     groupId: '2'
    // },
    {
        name: 'rocketModel',
        type: 'gltfModel',
        path: 'models/Rocket/Rocket.gltf'
    },
    {
        name: 'rocketTexture',
        type: 'texture',
        path: 'models/Rocket/RocketMaterialUV.webp'
    },
    {
        name: 'rocketNormal',
        type: 'texture',
        path: 'models/Rocket/RocketNormal.webp'
    },
    {
        name: 'rocketAo',
        type: 'texture',
        path: 'models/Rocket/rocketAmbientOC.webp'
    },
    {
        name: 'heavyBucketMoonModel',
        type: 'gltfModel',
        path: 'models/Moon/LogoMoon.gltf'
    },
    {
        name: 'moonModel',
        type: 'gltfModel',
        path: 'models/MoonBlank/MoonBlank.gltf'
    },
    {
        name: 'manModel',
        type: 'gltfModel',
        path: 'models/Man/SpaceMan.gltf'
    },
    // {
    //     name: 'manModel',
    //     type: 'gltfModel',
    //     path: 'models/Man/LowSpaceMan.gltf'
    // },
    {
        name: 'manTexture',
        type: 'texture',
        path: 'models/Man/lowResSpacemanlowResSpaceman_1-TM1001.webp'
    },
    {
        name: 'boosterTexture',
        type: 'texture',
        path: 'models/Man/booster.webp'
    },
    {
        name: 'packTexture',
        type: 'texture',
        path: 'models/Man/pack.webp'
    },
    {
        name: 'manAo',
        type: 'texture',
        path: 'models/Man/lowResSpacemanlowResSpaceman_1-AO1001.webp'
    },
    {
        name: 'manNormal',
        type: 'texture',
        path: 'models/Man/lowResSpaceman_1_norm.webp'
    },
    {
        name: 'heavyBucketMoonTexture',
        type: 'texture',
        path: 'models/Moon/LOWPOLYMOON-TM1001_copy.webp'
    },
    {
        name: 'heavyBucketMoonNormalMap',
        type: 'texture',
        path: 'models/Moon/LOWPOLYMOON-NM1001_copy.webp'
    },
    {
        name: 'heavyBucketMoonAoMap',
        type: 'texture',
        path: 'models/Moon/LOWPOLYMOON-AO1001_copy.webp'
    },
    {
        name: 'tinCircusMoonModel',
        type: 'gltfModel',
        path: 'models/TincircusMoon/TinCircusBillboard.gltf'
    },
    {
        name: 'gameMoonsTexture',
        type: 'texture',
        path: 'models/PoolsharkMoon/poolsharkMoon1_diff.webp'
    },
    {
        name: 'gameMoonsNormal',
        type: 'texture',
        path: 'models/PoolsharkMoon/poolsharkMoon1_norm.webp'
    },
    {
        name: 'gameMoonsAo',
        type: 'texture',
        path: 'models/PoolsharkMoon/poolsharkMoonpoolsharkMoon1-AO1001.webp'
    },
    {
        name: 'poolSharkBillboardTexture',
        type: 'texture',
        path: 'models/PoolsharkMoon/PoolsharkBillboardtextures.webp'
    },
    {
        name: 'tinCircusBillboardTexture',
        type: 'texture',
        path: 'models/TincircusMoon/Billboardtextures.webp'
    },
    {
        name: 'poolSharkMoonModel',
        type: 'gltfModel',
        path: 'models/PoolsharkMoon/PoolsharkBillboard.gltf'
    },
    {
        name: 'ufoModel',
        type: 'gltfModel',
        path: 'models/Ufo/UFO.gltf'
    },
    {
        name: 'ufoTexture',
        type: 'texture',
        path: 'models/Ufo/UFO_UV.webp'
    },
    {
        name: 'ufoNormal',
        type: 'texture',
        path: 'models/Ufo/UFO_NM.webp'
    }
]