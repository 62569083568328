import * as THREE from 'three';
import App from '../App.js';

export default class Man
{
    constructor(group) 
    {
            this.app = new App();
            this.scene = this.app.scene;
            this.scrollManager = this.app.scrollManager;
            this.group = group;
            this.debug = this.app.debug;
            this.scrollManager = this.app.scrollManager;
            this.moonGroup = this.app.world.moonGroup;
            this.resources = this.app.resources;
            this.page = this.app.scrollPageNumbers.page;
            this.time = this.app.time;

            //cache
            this.instance
            this.instanceGroup = new THREE.Group;
            this.instanceGroup.name = 'man container container';
            this.windowScrollY = 0;
            this.manMoveUpValueMulltiplier = 0.0014;
            this.manSpinValueMultiplier = -0.0014;
            this.manSpinValueOffset = Math.PI;
            this.scrollLastFrame = 0;
            this.animationSpeed = 0;
            this.visible = true;
            this.animationClipId = 1;

            // Debug
            if(this.debug.active)
            {
                this.debugFolder = this.debug.ui.addFolder('man');
            }

            // Resource
            this.resource = this.resources.items.manModel;
            this.setModel();
            this.setAnimation();
    }

    setModel()
    {
        this.instance = this.resource ? this.resource.scene : this.createCube();
        this.instance.castShadow = true;
        this.group.add(this.instance);

        this.instance.scale.set(175, 175, 175);

        this.instance.children[0].traverse((c) =>
        {
            if(c instanceof THREE.Mesh)
            {
                // console.log(c);
                if(c.name === "lowResSpaceman_lowResSpaceman_1") {
                    const testMaterial = new THREE.MeshLambertMaterial({
                        transparent: true
                    });
                    const texture = this.resources.items['manTexture'];
                    texture.encoding = THREE.sRGBEncoding;
                    testMaterial.map = texture;
                    texture.flipY = false;
                    texture.generateMipmaps = false;
                    texture.minFilter = THREE.NearestFilter;
        
                    testMaterial.normalMap = this.resources.items['manNormal'];
                    testMaterial.aoMap = this.resources.items['manAo'];
                    testMaterial.aoMapIntensity = 150;
                    c.material = testMaterial;
                }
                if(c.name === 'colar_Cylinder002') {
                    c.material.transparent = true;
                    c.material.flipY = false;
                }
                if(c.name === 'Rocket') {
                    c.material.transparent = true;
                    c.material.flipY = false;
                }
                if(c.name === 'pack_Roundcube')
                {
                    c.material.transparent = true;
                    c.material.flipY = false;
                }
                if(c.name === 'Helmet_Sphere') {
                    c.material.transparent = true;
                    c.material.opacity = 0.25;
                    c.material.roughness = 0.1;
                    c.material.flipY = false;
                }
                // console.log(c);
                c.castShadow = true;    

                c.frustumCulled = false;
            }
        })
    }


    createCube() 
    {
        return new THREE.Mesh(
            new THREE.BoxBufferGeometry(1,1,1),
            new THREE.MeshBasicMaterial()
        )
    }

    makeModelInvisible()
    {
        this.instance.traverse((c) => {
            if(c instanceof THREE.Mesh)
            {
                c.visible = false;
            }
        })
        this.visible = false;
    }

    makeModelVisible()
    {
        this.instance.traverse((c) => {
            if(c instanceof THREE.Mesh)
            {
                c.visible = true;
            }
        })
        this.visible = true;
    }

    setAnimation()
    {
        this.animation = {}
        
        // Mixer
        this.animation.mixer = new THREE.AnimationMixer(this.instance);
        
        // Actions
        this.animation.actions = {};
        
        this.animation.actions.walk = this.animation.mixer.clipAction(this.resource.animations[this.animationClipId]);
        // this.animation.actions.dive = this.animation.mixer.clipAction(this.resource.animations[0]);
        
        this.animation.actions.current = this.animation.actions.walk;
        this.animation.actions.current.play();

        // Play the action
        // this.animation.play = (name) =>
        // {
        //     const newAction = this.animation.actions[name];
        //     const oldAction = this.animation.actions.current;

        //     newAction.reset();
        //     newAction.play();
        //     newAction.crossFadeFrom(oldAction, 1);

        //     this.animation.actions.current = newAction;
        // }

        // // Debug
        // if(this.debug.active)
        // {
        //     const debugObject = {
        //         playIdle: () => { this.animation.play('idle') },
        //         playWalking: () => { this.animation.play('walking') },
        //         playRunning: () => { this.animation.play('running') }
        //     };
        //     this.debugFolder.add(debugObject, 'playIdle');
        //     this.debugFolder.add(debugObject, 'playWalking');
        //     this.debugFolder.add(debugObject, 'playRunning');
        // }
    }

    update()
    {

        this.page = this.app.scrollPageNumbers.page;            

        let speed = window.scrollY - this.scrollLastFrame;
        this.scrollLastFrame = window.scrollY;
        let s = (speed) * 0.0001;
        s = Math.fround(s);
        // console.log('my value', s);
        this.animation.mixer.update(30 * s);

        if(this.page > 9)
        {
            this.group.rotation.y += (s * 20);        
        }
        else
        {
            this.group.rotation.y = 0;
        }
    }
}