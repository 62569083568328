import EventEmitter from './EventEmitter.js'
import Stats from 'three/examples/jsm/libs/stats.module'

export default class Time extends EventEmitter
{
    constructor()
    {
        super()

        // Setup
        this.start = Date.now();
        this.current = this.start;
        this.elapsed = 0;
        this.delta = 16;
        this.elapsedSeconds = 0;

        //stats
        // this.stats = Stats();
        // document.body.appendChild(this.stats.dom);

        window.requestAnimationFrame(() =>
        {
            this.tick()
        })
    }

    tick()
    {
        const currentTime = Date.now();
        this.delta = currentTime - this.current;
        this.current = currentTime;
        this.elapsed = this.current - this.start;
        this.elapsedSeconds = Math.round((this.elapsed) * 0.001);

        this.trigger('tick')

        window.requestAnimationFrame(() =>
        {
            this.tick()
            // this.stats.update();
        })
    }
}