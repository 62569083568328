import * as THREE from 'three';
import App from "../App"
import spaceVertexShader from '../Shaders/space/vertex.glsl';
import spaceFragmentShader from '../Shaders/space/fragment.glsl';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'

export default class Space 
{
    constructor(group) 
    {
        this.app = new App();
        this.scene = this.app.scene;
        this.group = group;
        this.renderer = this.app.renderer;
        this.time = this.app.time;
        this.camera = this.app.camera;

        //cache
        this.instance;
        this.starDust = new THREE.PlaneBufferGeometry(0.2, 0.2, 1, 1);
        this.geometries = [];

        this.helper = new THREE.AxesHelper();

        this.start()
    }

    start()
    {
        this.generateSpace();
    }

    generateSpace()
    {
        this.material = new THREE.ShaderMaterial({
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,  
            uniforms:
            {
                uSize: { value: 300 * this.renderer.instance.getPixelRatio() }
            },  
            vertexShader: spaceVertexShader,
            fragmentShader: spaceFragmentShader
        });

        for (let i = 0; i < 500; i++) 
        {
            this.starDust = new THREE.PlaneBufferGeometry(0.1, 0.1);

            this.starDust.translate(
                (Math.random() - 0.5) * 25,
                (Math.random() - 0.5) * 15,
                ((Math.random() - 0.5) * 15) -10
            )

            this.geometries.push(this.starDust);
        }

        const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(this.geometries)

        this.instance = new THREE.Mesh(mergedGeometry, this.material);

        this.instance.renderOrder = 1;
        this.instance.material.depthTest = false;
        this.instance.material.depthWrite = false;
        this.instance.onBeforeRender = (r) => r.clearDepth();
        this.instance.name = 'spaceDust';
        this.group.renderOrder = 1;
        this.group.onBeforeRender = (r) => r.clearDepth();
            
        this.group.add(this.instance);

        this.group.rotation.y = Math.PI * 0.5;
            
    }
}