import * as THREE from 'three';
import App from '../App';
import Helper from '../Utils/Helper';
import EnemyOne from './EnemyOne';
export default class EnemyOneMaster
{
    constructor(gameScene)
    {
        this.app = new App();
        this.world = this.app.world;
        this.gameScene = gameScene;
        this.time = this.app.time;
        this.scene = this.app.scene;
        this.helper = new Helper();
        this.enemySpawner = this.app.enemySpawner;
        this.rocketGroup = this.app.world.rocketGroup;
        
        //cache
        this.enemyOneArrayAmount = 1;
        this.maxEnemies = 5;

        this.start();
    }

    start()
    {
        this.loadEnemyArray();
    }

    loadEnemyArray()
    {
        this.enemyOneArray = [this.enemyOneArrayAmount];

        for (let i = 0; i < this.enemyOneArrayAmount; i++) {
            this.enemyOneArray[i] = this.createEnemy();
        }
    }

    switchAllEnemyOnesOff()
    {
        this.enemyOneArray.forEach((enemy) => {
            enemy.instance.children[0].active = false;
            this.gameScene.remove(enemy.instance);
        })
    }

    createEnemy()
    {
        return new EnemyOne(this.enemySpawner.spawnPositionsArrayPageOne[this.helper.getRandomInt(0, this.enemySpawner.spawnPositionsNorth)], this.gameScene);
    }

    addEnemy()
    {
        // console.log('add enemy triggered ');
        if(this.enemyOneArrayAmount < this.maxEnemies)
        {
            this.enemyOneArrayAmount = this.enemyOneArray.push(this.createEnemy());
            // this.enemyOneArrayAmount += 1;
        }
    }

    makeAllEnemiesInactiveBarOne()
    {
        this.enemyOneArray.forEach((enemy, index) => {
            // console.log(index);
            if(index == 0)
            {
                enemy.instance.children[0].active = true;
            }
            else
            {

                enemy.instance.children[0].active = false;
                enemy.instance.children[0].originPosition = new THREE.Vector3(
                    this.spawnPositionsArrayPageOne[this.helper.getRandomInt(0, this.spawnPositionsNorth)].x,
                    this.spawnPositionsArrayPageOne[this.helper.getRandomInt(0, this.spawnPositionsNorth)].y,
                    this.spawnPositionsArrayPageOne[this.helper.getRandomInt(0, this.spawnPositionsNorth)].z
                    )
                // console.log(enemy);
                enemy.instance.children[0].active = false;
                enemy.instance.position.set(new THREE.Vector3(enemy.startPosition.x, enemy.startPosition.y, enemy.startPosition.z))
                enemy.instanceCube.position.set(new THREE.Vector3(enemy.startPosition.x, enemy.startPosition.y, enemy.startPosition.z))
                this.enemyOneArray.pop();
            }
            this.enemyOneArrayAmount = 1;
        })
    }

    update() 
    {
        this.enemyOneArray.forEach((enemy) => {
            // console.log(enemy);
            if(enemy.instance.children[0].active)
            {
                if(!this.rocketGroup)
                { 
                    this.rocketGroup = this.app.world.rocketGroup;
                }
                this.gameScene.add(enemy.instance);
                this.gameScene.add(enemy.instanceCube)
                enemy.update();
            }
            else
            {
                this.gameScene.remove(enemy.instance);
                this.gameScene.remove(enemy.instanceCube)
            }
            enemy.bulletArray.forEach((bullet) => {
                bullet.update();
            })
        })
    }
} 