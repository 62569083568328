import * as THREE from 'three';
import App from '../App.js';

import nebulaVertexShader from '../Shaders/nebula/vertex.glsl';
import nebulaFragmentShader from '../Shaders/nebula/fragment.glsl';

export default class Floor
{
    constructor(group)
    {
        this.app = new App();
        this.time = this.app.time;
        this.group = group;
        this.scene = this.app.scene;
        this.resources = this.app.resources;
        this.scrollManager = this.app.scrollManager;
        this.debug = this.app.debug;

        //cache
        this.windowScrollY = 0;
        this.scrollCrossoverMultiplier = 1;
        this.params = {
            depthColor: '#3c1891',
            surfaceColor: '#f352ff'
        };

        this.setGeometry();
        this.setMaterial();
        this.setMesh();

        // debug
        this.debugSetup();
    }

    setGeometry()
    {
        this.geometry = new THREE.PlaneGeometry(2, 2, 16, 16);
    }


    setMaterial()
    {
        this.material = new THREE.ShaderMaterial({
            vertexShader: nebulaVertexShader,
            fragmentShader: nebulaFragmentShader,
            uniforms:
            {
                uTime: { value: 0 },
                
                uBigWavesElevation: { value: 0.136 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
        
                uSmallWavesElevation: { value: 1.0 },
                uSmallWavesFrequency: { value: 0.605 },
                uSmallWavesSpeed: { value: 0.2 },
                uSmallIterations: { value: 4 },
        
                uDepthColor: { value: new THREE.Color(this.params.depthColor) },
                uSurfaceColor: { value: new THREE.Color(this.params.surfaceColor) },
                uColorOffset: { value: 0.113 },
                uColorMultiplier: { value: 5.151 }
            },
            transparent: true
        });
    }

    setMesh()
    {
        this.instance = new THREE.Mesh(this.geometry, this.material)
        this.instance.receiveShadow = true;
        this.instance.name = "fullScreenShaderFx"
        this.instance.position.z = 8.99;
        this.instance.scale.set(4, 4, 4);
        this.instance.position.y = 2;
        this.group.add(this.instance);
    }

    debugSetup()
    {
        if(this.debug.active)
        {

            
            this.debugFolder = this.debug.ui.addFolder('nebula');

            this.debugFolder.addColor(this.params, 'depthColor').onChange(() => { this.material.uniforms.uDepthColor.value.set(this.params.depthColor) })
            this.debugFolder.addColor(this.params, 'surfaceColor').onChange(() => { this.material.uniforms.uSurfaceColor.value.set(this.params.surfaceColor) })

            this.debugFolder.add(this.material.uniforms.uBigWavesElevation, 'value').min(0).max(1).step(0.001).name('uBigWavesElevation')
            this.debugFolder.add(this.material.uniforms.uBigWavesFrequency.value, 'x').min(0).max(10).step(0.001).name('uBigWavesFrequencyX')
            this.debugFolder.add(this.material.uniforms.uBigWavesFrequency.value, 'y').min(0).max(10).step(0.001).name('uBigWavesFrequencyY')
            this.debugFolder.add(this.material.uniforms.uBigWavesSpeed, 'value').min(0).max(4).step(0.001).name('uBigWavesSpeed')
    
    
            this.debugFolder.add(this.material.uniforms.uSmallWavesElevation, 'value').min(0).max(1).step(0.001).name('uSmallWavesElevation')
            this.debugFolder.add(this.material.uniforms.uSmallWavesFrequency, 'value').min(0).max(30).step(0.001).name('uSmallWavesFrequency')
            this.debugFolder.add(this.material.uniforms.uSmallWavesSpeed, 'value').min(0).max(4).step(0.001).name('uSmallWavesSpeed')
            this.debugFolder.add(this.material.uniforms.uSmallIterations, 'value').min(0).max(5).step(1).name('uSmallIterations')
    
            this.debugFolder.add(this.material.uniforms.uColorOffset, 'value').min(0).max(1).step(0.001).name('uColorOffset')
            this.debugFolder.add(this.material.uniforms.uColorMultiplier, 'value').min(0).max(10).step(0.001).name('uColorMultiplier')

            this.debugFolder.add(this.instance.position, 'x').min(-100).max(100).step(1).name('position x');
            this.debugFolder.add(this.instance.position, 'y').min(-100).max(100).step(1).name('position y');
            this.debugFolder.add(this.instance.position, 'z').min(-100).max(100).step(1).name('position z');


            this.debugFolder.add(this.instance.rotation, 'x').min(-6).max(6).step(0.1).name('rotation x');
            this.debugFolder.add(this.instance.rotation, 'y').min(-6).max(6).step(0.1).name('rotation y');
            this.debugFolder.add(this.instance.rotation, 'z').min(-6).max(6).step(0.1).name('rotation z');

            this.debugFolder.add(this.instance.scale, 'x').min(-10).max(10).step(0.1).name('scale x');
            this.debugFolder.add(this.instance.scale, 'y').min(-10).max(10).step(0.1).name('scale y');
            this.debugFolder.add(this.instance.scale, 'z').min(-10).max(10).step(0.1).name('scale z');
        }
    }

    update()
    {
        this.material.uniforms.uTime.value = this.time.elapsed * 0.0004;
    }
}