import * as THREE from 'three';
import EventEmitter from './EventEmitter';
import App from "../App";
import Helper from './Helper';

export default class ScrollTimeline extends EventEmitter
{
    constructor() 
    {
        super();

        this.app = new App();
        this.helper = new Helper();
        this.mouse = this.app.mouse;
        this.scene = this.app.scene;
        this.sizes = this.app.sizes;
        this.debug = this.app.debug;
        this.camera = this.app.camera;
        this.resources = this.app.resources;
        this.scrollManager = this.app.scrollManager;
        this.moonGroup = this.app.world.moonGroup;
        this.heavyBucketGamesMoonGroup = this.app.world.heavyBucketGamesMoonGroup;
        this.manContainer = this.app.world.manContainer;
        this.page = this.app.scrollPageNumbers.page;
        this.enemySpawner = this.app.enemySpawner;

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.man = this.app.world.man;
            this.heavyBucketMoon = this.app.world.heavyBucketMoon;
            this.poolSharkMoon = this.app.world.poolSharkMoon;
            this.tinCircusMoon = this.app.world.tinCircusMoon;
            this.rocket = this.app.world.rocket;
            this.enemyOneMaster = this.app.world.enemyOneMaster;
            this.moon = this.app.world.moon.instance;
            this.start();
            this.scrollTimeline();

        })

        //cache
        this.scrollUpdatedForThisPage = false;
        this.currentPage = 0;
        this.windowScrollX = 0;
        this.windowScrollY = 0;
        this.moonMoveUpValueMulltiplier = 0.0018;
        this.moonMoveSidewaysValueMulltiplier = 0.0026;
        this.replacementSiidewaysVAlueMiltiplier = 0.0020
        this.moonSpinValueMulltiplier = 0.0034;
        this.moonSpinWhenSidewaysValueMulltiplier = -0.0005;
        this.manSpinValueOffset = Math.PI * 0.1;
        this.manMoveUpValueMulltiplier = 0.0014;
        this.manSpinValueMultiplier = -0.0014;
        this.manSpinWhenSidewaysValueMultiplier = 0;
        this.manSpinSidewaysValueOffset = 0;
        this.defaultMoonModelRotation = new THREE.Quaternion();
        this.threeWorldOffTheScreenLeftValueFromCenter = 5;
        this.threeWorldOffTheScreenBottomValueFromCenter = 5;
        this.manHeightWhenWalkingSidewaysArossTheScreen = 1;
        this.manHeightWhenWalkingVertical = 1.41;
        this.skrinkGamePlanetsBy = 0.002;
        this.sizeWhenManAndMoonMoveVertical = 1.5;
        // for vertical
        this.moonScaleForNewMoon = 0.0155;
        this.scaleReducerWhenMovingSideways = 1;

        // for sideways
        this.moonScaleForNewMoon2 = 0.0155;
        this.scaleReducerWhenMovingSideways2 = 1;
        this.heightOnScreenWhenMovingSideways = -1.1;

        this.zGameMoonPosition = 0;
        this.zMoonPosition = -5;
        this.spotLightHelper;

        this.runScreenResize();

    }

    runScreenResize() 
    {
        this.sizes.on('resize', () => {
            this.threeWorldOffTheScreenLeftValueFromCenter = this.mouse.positionOffTheLeftOfTheScreenInThreeSpace.x - 1.5;
            this.moonMoveSidewaysValueMulltiplier = (this.mouse.positionOffTheLeftOfTheScreenInThreeSpace.x * 0.001) * -1;
            // this.moonMoveSidewaysValueMulltiplier = (0.0000020 * window.innerWidth);

            // if(this.tinCircusMoon) {
            //     let value = (this.mouse.gamePlanetPosition.x * 0.75) < -1 ? -1 : this.mouse.gamePlanetPosition.x * 0.75;
            //     value += this.skrinkGamePlanetsBy;
            //     this.tinCircusMoon.group.scale.set(-value,-value,-value);
            //     this.tinCircusMoon.group.position.set(-this.mouse.gamePlanetPosition.x, this.tinCircusMoon.group.position.y,0);
            // }
            // if(this.poolSharkMoon) {
            //     let value = (this.mouse.gamePlanetPosition.x * 0.75) < -1 ? -1 : this.mouse.gamePlanetPosition.x * 0.75;
            //     value += this.skrinkGamePlanetsBy;
            //     this.poolSharkMoon.group.scale.set(-value,-value,-value);  
            //     this.poolSharkMoon.group.position.set(this.mouse.gamePlanetPosition.x, this.poolSharkMoon.group.position.y,0);
            // }
        })
    }

    createSphere() 
    {
        return new THREE.Mesh(
            new THREE.SphereBufferGeometry(0.01,16,16),
            new THREE.MeshBasicMaterial({
                color: 'grey',
            })
        )
    }

    start() 
    {
        this.moonGroup.add(this.moon);
        this.manContainer.rotation.x = (this.windowScrollY * 0.002) + Math.PI;
        this.manContainer.remove(this.man);
        this.heavyBucketGamesMoonGroup.add(this.heavyBucketMoon.instance);
        // moons positions
        if(this.heavyBucketMoon) {
            this.heavyBucketMoon.instance.position.set(0, 0, 0);
        }

        if(this.tinCircusMoon) {
            let value = (this.mouse.gamePlanetPosition.x * 0.75) < -1 ? -1 : this.mouse.gamePlanetPosition.x * 0.75;
            value += this.skrinkGamePlanetsBy;
            this.tinCircusMoon.group.scale.set(-value,-value,-value);
            this.tinCircusMoon.group.position.set(-this.mouse.gamePlanetPosition.x,0,0);
        }
        if(this.poolSharkMoon) {
            let value = (this.mouse.gamePlanetPosition.x * 0.75) < -1 ? -1 : this.mouse.gamePlanetPosition.x * 0.75;
            value += this.skrinkGamePlanetsBy;
            this.poolSharkMoon.group.scale.set(-value,-value,-value);  
            this.poolSharkMoon.group.position.set(this.mouse.gamePlanetPosition.x,0,0);    
        }
    }


    scrollTimeline() 
    {
        this.runScrollSwitchStatement();

        const pos = this.helper.getThreePositionFromScreenPosition(new THREE.Vector3(this.sizes.width * 0.5, this.sizes.height, 0), this.camera);

        this.threeWorldOffTheScreenLeftValueFromCenter = this.mouse.positionOffTheLeftOfTheScreenInThreeSpace.x - 1.5;
        this.moonMoveSidewaysValueMulltiplier = ((this.mouse.positionOffTheLeftOfTheScreenInThreeSpace.x * 0.0005) * 2) * -1;

        this.threeWorldOffTheScreenBottomValueFromCenter = (pos.y * -1) + 1.5;

        this.moonMoveUpValueMulltiplier = (1 / this.sizes.height) * 1.5;

        this.scrollManager.on('three-scroll', () => {
            this.windowScrollY = window.scrollY;
            this.windowScrollX = window.scrollX;
            this.page = this.app.scrollPageNumbers.page;

            if(this.page !== this.currentPage)
            {
                this.scrollUpdatedForThisPage = false;
            }

            this.currentPage = this.page;

            this.runScrollSwitchStatement();
        })
    }


    runScrollSwitchStatement() 
    {
        switch(this.page) 
        {
            case 1:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;
    
                    this.moon.rotation.z = 0;
                    this.moon.rotation.x = 0;
                    // moon group
                    this.moonGroup.remove(this.moon);
    
                    this.moonGroup.scale.set(1,1,1);
                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
    
                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    this.manContainer.remove(this.man.instance);
                    this.manContainer.rotation.x = 0;
                    this.manContainer.rotation.z = 0;
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }
                    if(this.enemyOneMaster)
                    {
                        this.enemySpawner.spawnerOff = false;
                    }
                }

                break;
            }
            case 2:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;

                    if(!this.audioManager)
                    {
                        this.audioManager = this.app.world.audioManager;
                    }

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

            
                    // this.audioManager.value = 0.0;
                    // this.audioManager.audio['lightTune'].file.setVolume(this.audioManager.value);
                    this.moon.rotation.z = 0;

                    // moon group
                    this.moonGroup.add(this.moon);

                    this.moonGroup.scale.set(1,1,1);

                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    if(this.man.visible === false)
                    {
                        this.man.makeModelVisible();
                    }
                    this.manContainer.add(this.man.instance);
                    this.manContainer.rotation.z = 0
                    if(this.man.position) {
                        this.man.instance.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }

                    if(this.enemyOneMaster)
                    {
                        this.enemySpawner.spawnerOff = true;
                    }
                }

                //update stuff
                const value = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page + 1];
                this.manContainer.rotation.x = - ((value * this.manSpinValueMultiplier) + this.manSpinValueOffset);
                this.moonGroup.position.set(0, (value * this.moonMoveUpValueMulltiplier) - this.threeWorldOffTheScreenBottomValueFromCenter, this.zMoonPosition);
                this.moon.rotation.x = - value * this.moonSpinValueMulltiplier;    

                break;
            }
            case 3:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;
                

                    if(this.enemyOneMaster)
                    {
                        this.enemyOneMaster.switchAllEnemyOnesOff();
                        this.enemySpawner.spawnerOff = true;
                    }

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    //moon text fade
                    this.moon.rotation.z = 0;

                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(1,1,1);

                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    this.manContainer.rotation.z = 0
                    if(this.man.position) {
                        this.man.instance.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }

                    if(this.enemyOneMaster)
                    {
                        this.enemySpawner.spawnerOff = true;
                    }
                }

                //update stuff
                const value0 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page];
                this.moon.rotation.x = - value0 * this.moonSpinValueMulltiplier;
                this.moonGroup.position.set(0, (value0 * this.moonMoveUpValueMulltiplier) - this.threeWorldOffTheScreenBottomValueFromCenter, this.zMoonPosition);
                this.manContainer.rotation.x = - ((value0 * this.manSpinValueMultiplier) + this.manSpinValueOffset);

                break;
            }
            case 4:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;

                    if(this.enemyOneMaster)
                    {
                        this.enemyOneMaster.switchAllEnemyOnesOff();
                        this.enemySpawner.spawnerOff = true;
                    }

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    // moon come up
                    this.moon.rotation.z = 0;

                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(1,1,1);

                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);

                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    this.manContainer.rotation.z = 0
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }
                    if(this.enemyOneMaster)
                    {
                        // this.enemyOneMaster.switchAllEnemyOnesOff();
                        this.enemySpawner.spawnerOff = true;
                    }
                }

                const value1 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 1];
                this.moon.rotation.x = - value1 * this.moonSpinValueMulltiplier;
                this.moonGroup.position.set(0, (value1 * this.moonMoveUpValueMulltiplier) - this.threeWorldOffTheScreenBottomValueFromCenter, this.zMoonPosition);
                this.manContainer.rotation.x = - ((value1 * this.manSpinValueMultiplier) + this.manSpinValueOffset);

                break;
            }
            case 5:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;
                

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    // moon come up, first site of guy
                    this.moon.rotation.z = 0;
                    this.manContainer.rotation.z = 0;

                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(1,1,1);
                    //heavy bucket moon and tin circus moon stuff
                    // float in
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }

                    if(this.enemyOneMaster)
                    {
                        // this.enemyOneMaster.switchAllEnemyOnesOff();
                        this.enemySpawner.spawnerOff = true;
                    }
                }

                const value2 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 2];
                this.moon.rotation.x = - value2 * this.moonSpinValueMulltiplier;
                this.manContainer.rotation.x = - ((value2 * this.manSpinValueMultiplier) + this.manSpinValueOffset);
                this.moonGroup.position.set(0, (value2 * this.moonMoveUpValueMulltiplier) - this.threeWorldOffTheScreenBottomValueFromCenter, this.zMoonPosition);

                break;
            }
            case 6:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    // guy intop of moon, moon 75% visible
                    this.moon.rotation.z = 0;
                    this.manContainer.rotation.z = 0;

                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(1,1,1);

                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }
                }
                const value3 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 3];
                this.moon.rotation.x = - value3 * this.moonSpinValueMulltiplier;
                this.moonGroup.position.set(0, (value3 * this.moonMoveUpValueMulltiplier) - this.threeWorldOffTheScreenBottomValueFromCenter, this.zMoonPosition);
                this.manContainer.rotation.x = - ((value3 * this.manSpinValueMultiplier) + this.manSpinValueOffset);

                break;
            }
            case 7:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;
            
                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    //guy on side of moon top of his head straight on, moon center page
                    this.moon.rotation.z = 0;
                    this.manContainer.rotation.z = 0;

                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(1,1,1);

                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }
                }

                const value4 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 4];
                this.moon.rotation.x = - value4 * this.moonSpinValueMulltiplier;
                this.moonGroup.position.set(0, (value4 * this.moonMoveUpValueMulltiplier) - this.threeWorldOffTheScreenBottomValueFromCenter, this.zMoonPosition);
                this.manContainer.rotation.x = - ((value4 * this.manSpinValueMultiplier) + this.manSpinValueOffset);

                break;
            }
            case 8:
            {
                // if(this.scrollUpdatedForThisPage === false)
                // {
                //     this.scrollUpdatedForThisPage = true;

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    // guy upside down moon starting to leave out the top
                    this.moon.rotation.z = 0;

                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(1,1,1);

                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.add(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.add(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    this.manContainer.rotation.z = 0;
                    this.manContainer.add(this.man.instance);
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }
                // }

                const value5 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 5];
                this.moon.rotation.x = - value5 * this.moonSpinValueMulltiplier;
                this.moonGroup.position.set(0, (value5 * this.moonMoveUpValueMulltiplier) - this.threeWorldOffTheScreenBottomValueFromCenter, this.zMoonPosition);
                this.manContainer.rotation.x = - ((value5 * this.manSpinValueMultiplier) + this.manSpinValueOffset);

                break;
            }
            case 9:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;
                
                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    //guy upside down, but over the lip of the moon, moon 25% visible
                    this.moon.rotation.z = 0;
                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(1,1,1);

                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.add(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.add(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    this.manContainer.rotation.z = 0;
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }
                }

                const value6 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 6];
                this.moon.rotation.x = - value6 * this.moonSpinValueMulltiplier;
                this.moonGroup.position.set(0, (value6 * this.moonMoveUpValueMulltiplier) - this.threeWorldOffTheScreenBottomValueFromCenter, this.zMoonPosition);
                this.manContainer.rotation.x = - ((value6 * this.manSpinValueMultiplier) + this.manSpinValueOffset);

                break;
            }
            case 10:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    //screen clear and teleport moon to the left
                    this.moon.rotation.z = 0;
                    this.manContainer.rotation.z = 0;

                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(1,1,1);

                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.add(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.add(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }
                }

                const value61 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 7];
                this.moon.rotation.x = - value61 * this.moonSpinValueMulltiplier;
                this.moonGroup.position.set(0, (value61 * this.moonMoveUpValueMulltiplier) - this.threeWorldOffTheScreenBottomValueFromCenter, this.zMoonPosition);
                this.manContainer.rotation.x = - ((value61 * this.manSpinValueMultiplier) + this.manSpinValueOffset);

                break;
            }
            case 11:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    // heavy bucket games moons appear from the bottom or side
                    this.moon.rotation.z = 0;
                    this.manContainer.rotation.z = 0;

                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(1,1,1);
                    //heavy bucket moon and tin circus moon stuff
                    // float back
                    this.poolSharkMoon.group.add(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.add(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 1)
                    {
                        this.man.animationClipId = 1;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }
                    if(this.enemyOneMaster)
                    {
                        this.enemyOneMaster.switchAllEnemyOnesOff();
                        this.enemySpawner.spawnerOff = true;
                    }
                }

                const value62 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 8];
                this.moon.rotation.x = - value62 * this.moonSpinValueMulltiplier;
                this.moonGroup.position.set(0, (value62 * this.moonMoveUpValueMulltiplier) - this.threeWorldOffTheScreenBottomValueFromCenter, this.zMoonPosition);
                this.manContainer.rotation.x = - ((value62 * this.manSpinValueMultiplier) + this.manSpinValueOffset);

                break;
            }
            case 12:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    
                    this.heavyBucketMoonText.textFuckOff();
    
                    // heavy bucket games moons centered
                    this.moon.rotation.z = 0;
                    this.moon.rotation.x = 0;
    
                    // moon group
                    this.moonGroup.remove(this.moon);
                    this.moonGroup.scale.set(1,1,1);
    
                    //heavy bucket moon and tin circus moon stuff
                    // float back further
                    this.poolSharkMoon.group.add(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.add(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.pointlightTinCircus);
    
    
                    //man stuff
                    if(this.man.animationClipId !== 0)
                    {
                        this.man.animationClipId = 0;
                        this.man.setAnimation()
                    }
                    this.manContainer.rotation.x = 0
                    this.manContainer.rotation.z = 0
                    this.manContainer.remove(this.man.instance);
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingVertical;
                        this.man.instance.rotation.y = 0;
                    }
                    if(this.enemyOneMaster)
                    {
                        this.enemyOneMaster.switchAllEnemyOnesOff();
                        this.enemySpawner.spawnerOff = true;
                    }
                }
                break;
            }
            case 13:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;
                
                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    // heavy bucket games moons moving out the way of incoming moon walker
                    this.moon.rotation.x = 0;
                    this.manContainer.rotation.x = 0

                    // moon group
                    this.moonGroup.add(this.moon);

                    this.moonGroup.scale.set(0.65,0.65,0.65);
                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.add(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.add(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.add(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 0)
                    {
                        this.man.animationClipId = 0;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingSidewaysArossTheScreen;
                        this.man.instance.rotation.y = Math.PI * 0.5;
                    }
                    if(this.enemyOneMaster)
                    {
                        this.enemyOneMaster.switchAllEnemyOnesOff();
                        this.enemySpawner.spawnerOff = true;
                    }
                }

                const value7 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 1];
                this.moon.rotation.z = - value7 * this.moonSpinWhenSidewaysValueMulltiplier;
                this.moonGroup.position.set((value7 * this.moonMoveSidewaysValueMulltiplier) - (this.threeWorldOffTheScreenLeftValueFromCenter * -1), this.heightOnScreenWhenMovingSideways, 0);
                this.manContainer.rotation.z = - ((value7 * this.manSpinWhenSidewaysValueMultiplier) + this.manSpinSidewaysValueOffset);
                
                break;
                
            }
            case 14:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }
                    this.heavyBucketMoonText.textFuckOff();

                    //moon walker appears from the left side 50% visible
                    this.moon.rotation.x = 0;
                                    
                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(0.65,0.65,0.65);
                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 0)
                    {
                        this.man.animationClipId = 0;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    this.manContainer.rotation.x = 0;
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingSidewaysArossTheScreen;
                        this.man.instance.rotation.y = Math.PI * 0.5;
                    }
                    if(this.enemyOneMaster)
                    {
                        // this.enemyOneMaster.switchAllEnemyOnesOff();
                        this.enemySpawner.spawnerOff = true;
                    }
                }

                const value8 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 2];
                this.moon.rotation.z = - value8 * this.moonSpinWhenSidewaysValueMulltiplier;
                this.moonGroup.position.set((value8 * this.moonMoveSidewaysValueMulltiplier) - (this.threeWorldOffTheScreenLeftValueFromCenter * -1), this.heightOnScreenWhenMovingSideways, 0);
                this.manContainer.rotation.z = - ((value8 * this.manSpinWhenSidewaysValueMultiplier) + this.manSpinSidewaysValueOffset);

                break;
            }
            case 15:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;

                    if(this.enemyOneMaster)
                    {
                        this.enemyOneMaster.switchAllEnemyOnesOff();
                        this.enemySpawner.spawnerOff = true;
                    }
                    
                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }

                    this.heavyBucketMoonText.textFuckOff();
                    // moon walker fully visible tracking from left to right with guy visible walking ontop
                    this.moon.rotation.x = 0;

                    // moon group
                    this.moonGroup.add(this.moon);
                    this.moonGroup.scale.set(0.65,0.65,0.65);
                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 0)
                    {
                        this.man.animationClipId = 0;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    this.manContainer.rotation.x = 0;
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingSidewaysArossTheScreen;
                        this.man.instance.rotation.y = Math.PI * 0.5;
                    }
                    if(this.enemyOneMaster)
                    {
                        this.enemySpawner.spawnerOff = true;
                    }
                }

                const value9 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page -3];
                this.moon.rotation.z = - value9 * this.moonSpinWhenSidewaysValueMulltiplier;
                this.moonGroup.position.set((value9 * this.moonMoveSidewaysValueMulltiplier) - (this.threeWorldOffTheScreenLeftValueFromCenter * -1), this.heightOnScreenWhenMovingSideways, 0);
                this.manContainer.rotation.z = - ((value9 * this.manSpinWhenSidewaysValueMultiplier) + this.manSpinSidewaysValueOffset);
                
                break;
            }
            case 16:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;
                
                    if(this.enemyOneMaster)
                    {
                        this.enemyOneMaster.switchAllEnemyOnesOff();
                        this.enemySpawner.spawnerOff = true;
                    }

                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }

                    this.heavyBucketMoonText.textFuckOff();
                    // same as above. Now centered
                    this.moon.rotation.x = 0;
                    this.manContainer.rotation.x = 0;

                    // moon group
                    this.moonGroup.add(this.moon);

                    this.moonGroup.scale.set(0.65,0.65,0.65);
                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 0)
                    {
                        this.man.animationClipId = 0;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingSidewaysArossTheScreen;
                        this.man.instance.rotation.y = Math.PI * 0.5;
                    }
                    //rocket stuff
                    if(this.enemyOneMaster)
                    {
                        this.enemySpawner.spawnerOff = true;
                    }
                }

                const value10 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 4];
                this.moon.rotation.z = - value10 * this.moonSpinWhenSidewaysValueMulltiplier;
                this.moonGroup.position.set((value10 * this.moonMoveSidewaysValueMulltiplier) - (this.threeWorldOffTheScreenLeftValueFromCenter * -1), this.heightOnScreenWhenMovingSideways, 0);
                this.manContainer.rotation.z = - ((value10 * this.manSpinWhenSidewaysValueMultiplier) + this.manSpinSidewaysValueOffset);

                break;
            }
            case 17:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;
                
                    if(!this.heavyBucketMoonText)
                    {
                        this.heavyBucketMoonText = this.app.world.heavyBucketMoonText;
                    }

                    this.heavyBucketMoonText.textFuckOff();

                    if(!this.audioManager)
                    {
                        this.audioManager = this.app.world.audioManager;
                    }
            
                    // this.audioManager.value = 0.0;
                    // this.audioManager.audio['lightTune'].file.setVolume(this.audioManager.value);

                    // moon walker about to leave the screen
                    this.moon.rotation.x = 0;
                    this.moonGroup.add(this.moon);

                    this.moonGroup.scale.set(0.65,0.65,0.65);
                    //heavy bucket moon and tin circus moon stuff
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 0)
                    {
                        this.man.animationClipId = 0;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    this.manContainer.rotation.x = 0;
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingSidewaysArossTheScreen;
                        this.man.instance.rotation.y = Math.PI * 0.5;
                    }
                    //rocket stuff
                    if(this.enemyOneMaster)
                    {
                        this.enemySpawner.spawnerOff = true;
                    }
                }

                const value11 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 5];
                this.moon.rotation.z = - value11 * this.moonSpinWhenSidewaysValueMulltiplier;
                this.moonGroup.position.set((value11 * this.moonMoveSidewaysValueMulltiplier) - (this.threeWorldOffTheScreenLeftValueFromCenter * -1), this.heightOnScreenWhenMovingSideways, 0);
                this.manContainer.rotation.z = - ((value11 * this.manSpinWhenSidewaysValueMultiplier) + this.manSpinSidewaysValueOffset);

                break;
            }
            case 18:
            {
                if(this.scrollUpdatedForThisPage === false)
                {
                    this.scrollUpdatedForThisPage = true;

                    //heavy bucket games back in position???
                    this.moon.rotation.x = 0;
                    this.manContainer.rotation.x = 0;
                    this.moonGroup.add(this.moon);

                    //heavy bucket moon and tin circus moon stuff
                    // float to forground
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.instance);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.instance);
                    this.poolSharkMoon.group.remove(this.poolSharkMoon.pointlightPoolShark);
                    this.tinCircusMoon.group.remove(this.tinCircusMoon.pointlightTinCircus);
                    //man stuff
                    if(this.man.animationClipId !== 0)
                    {
                        this.man.animationClipId = 0;
                        this.man.setAnimation()
                    }
                    this.manContainer.add(this.man.instance);
                    if(this.man.instance) {
                        this.man.instance.position.y = this.manHeightWhenWalkingSidewaysArossTheScreen;
                        this.man.instance.rotation.y = Math.PI * 0.5;
                    }
                    if(this.enemyOneMaster)
                    {
                        this.enemySpawner.spawnerOff = false;
                    }
                }

                const value12 = this.windowScrollY - this.app.scrollPageNumbers.centerOfEachPageScollValue[this.page - 6];
                this.moon.rotation.z = - value12 * this.moonSpinWhenSidewaysValueMulltiplier;
                this.moonGroup.position.set((value12 * this.moonMoveSidewaysValueMulltiplier) - (this.threeWorldOffTheScreenLeftValueFromCenter * -1), this.heightOnScreenWhenMovingSideways, 0);
                this.manContainer.rotation.z = - ((value12 * this.manSpinWhenSidewaysValueMultiplier) + this.manSpinSidewaysValueOffset);

                break;
            }
            default:
                break;
        }
    }
}