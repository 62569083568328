import * as THREE from 'three'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import App from '../App.js'
import AudioManager from '../Utils/AudioManager.js';
import EnemyOneMaster from './EnemyOneMaster.js'
import Environment from './Environment.js'
import Floor from './Floor.js'
import Galaxy from './Galaxy.js'
import Man from './Man.js'
import Moon from './Moon.js'
import MoonBlank from './MoonBlank.js';
import PointerSprite from './PointerSprite.js';
import Rocket from './Rocket.js'
import Space from './Space.js'
import GameMoonsText from './Text/GameMoonText.js';
import HeavyBucketMoonText from './Text/HeavyBucketMoonText.js';
import ScoreText from './Text/ScoreText.js'
import RendererCompile from '../Utils/RendererCompile.js';

export default class World
{
    constructor()
    {

        gsap.registerPlugin(ScrollTrigger);

        this.app = new App();
        this.camera = this.app.camera;
        this.time = this.app.time;
        this.delta = this.app.time.delta;
        this.resources = this.app.resources;
        this.scene = this.app.scene;
        this.mouse = this.app.mouse;
        this.moonGroup = new THREE.Group();
        this.moonGroup.name = 'moonGroup';
        this.scene.add(this.moonGroup);
        this.heavyBucketGamesMoonGroup = new THREE.Group();
        this.heavyBucketGamesMoonGroup.name = 'heavyBucketGamesMoonGroup';
        this.scene.add(this.heavyBucketGamesMoonGroup);
        this.tinCircusGamesMoonGroup = new THREE.Group();
        this.tinCircusGamesMoonGroup.name = 'tinCircusGamesMoonGroup';
        this.poolSharkGamesMoonGroup = new THREE.Group();
        this.poolSharkGamesMoonGroup.name = 'poolSharkGamesMoonGroup';
        this.scene.add(this.tinCircusGamesMoonGroup);
        this.scene.add(this.poolSharkGamesMoonGroup);
        this.manContainer = new THREE.Group();
        this.manContainer.name = 'manContainer';
        this.moonGroup.add(this.manContainer);
        this.spaceGroup = new THREE.Group();
        this.spaceGroup.name = 'spaceGroup';
        this.scene.add(this.spaceGroup);
        this.page = this.app.scrollPageNumbers.page;
        this.galaxyGroup = new THREE.Group();
        this.scene.add(this.galaxyGroup);
        this.rocketGroup = new THREE.Group();
        this.scene.add(this.rocketGroup);
        this.heavyBucketMoonTextGroup = new THREE.Group();
        this.heavyBucketMoonTextGroup.name = 'heavyBucketMoonTextGroup';
        this.scene.add(this.heavyBucketMoonTextGroup);
        this.gameMoonsTextGroup = new THREE.Group();
        this.gameMoonsTextGroup.name = 'gameMoonsTextGroup';
        this.scene.add(this.gameMoonsTextGroup);
        this.gameScene = new THREE.Group();
        this.gameScene.name = 'gameScene';
        this.gameScene.add(this.rocketGroup);
        this.scene.add(this.gameScene);

        //cache
        this.heavyBucketMoonDistanceOut =  this.calculateHeavyBucketMoonDistanceOut(new THREE.Vector3(0, 0, -this.camera.cullingDistance));
        this.scrollLastFram = 1;
        this.gamePlanetsHeight = -2.3;
        this.heavyBucketMoonStartingZAxis = -1.3;
        this.heavyBucketGamesMoonGroup.position.z = this.heavyBucketMoonStartingZAxis;
        this.canClickOnGameMoonMoreIsQuicker = 5;

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.scoreText = new ScoreText();
            this.floor = new Floor(this.app.cameraGroup);
            this.moon = new MoonBlank('moonModel', this.moonGroup);
            this.heavyBucketMoon = new Moon('heavyBucketMoonModel');
            this.poolSharkMoon = new Moon('poolSharkMoonModel', this.poolSharkGamesMoonGroup);
            this.tinCircusMoon = new Moon('tinCircusMoonModel', this.tinCircusGamesMoonGroup);
            this.man = new Man(this.manContainer);
            this.environment = new Environment();
            this.galaxy = new Galaxy(this.spaceGroup);
            this.space = new Space(this.spaceGroup);
            this.rocket = new Rocket('rocketModel', this.rocketGroup, this.gameScene);
            this.enemyOneMaster = new EnemyOneMaster(this.gameScene);
            this.audioManager = new AudioManager();
            this.pointerSprite = new PointerSprite(this.scene, this.mouse, this.camera);
            this.heavyBucketMoonText = new HeavyBucketMoonText(this.heavyBucketMoonTextGroup);
            this.gameMoonsText = new GameMoonsText(this.gameMoonsTextGroup);

            this.gsapFunctionForHeavyBucketMoon();
            this.gsapFunctionForTinCircusMoon();
            this.gsapFunctionForPoolSharkMoon();
        })

        // this.app.sizes.on('resize', () => {
        //     this.gsapFunctionForTinCircusMoon();
        //     this.gsapFunctionForPoolSharkMoon();
        // })

        this.rendererCompile = new RendererCompile(this.app.renderer, this.scene, this.camera);

    }

    calculateHeavyBucketMoonDistanceOut(vectorPosition) 
    {
        const heavyBucketMoonFarOutVector3 = vectorPosition;
        return heavyBucketMoonFarOutVector3.distanceTo(new THREE.Vector3(0,0,0));
    }

    gsapFunctionForHeavyBucketMoon()
    {

        const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".webgl",
            start: 0,
            end: window.innerHeight * 16,
            scrub: true,
            markers: false
        }
        });
        tl.to(this.heavyBucketGamesMoonGroup.position, {z: -1.5, duration: 0})
        tl.to(this.heavyBucketGamesMoonGroup.position, {z: -100, duration: 40})
        tl.to(this.heavyBucketGamesMoonGroup.position, {z: -1.5, duration: 20})
    }


    gsapFunctionForPoolSharkMoon()
    {

    const xValueByMousePosition = this.mouse.gamePlanetPosition.x;

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".webgl",
            start: window.innerHeight * 3,
            end: window.innerHeight * 18,
            scrub: true,
            markers: false
        }
        });
        tl.to(this.poolSharkGamesMoonGroup.position, {x: -window.innerWidth * 0.06, y: 0, z: -100, duration: 0 })
        tl.to(this.poolSharkGamesMoonGroup.position, {z: -1.5, y: this.gamePlanetsHeight, x: xValueByMousePosition, duration: 40 })
        tl.to(this.poolSharkGamesMoonGroup.position, {x: -window.innerWidth * 0.06, y: 0, z: -100, duration: 40 })

    const t2 = gsap.timeline({
        scrollTrigger: {
            trigger: ".webgl",
            start: window.innerHeight * 3,
            end: window.innerHeight * 18,
            scrub: true,
            markers: false
        }
        });
        t2.to(this.poolSharkGamesMoonGroup.rotation, {x: 0, y: 0, z: 0, duration: 38 })
        t2.to(this.poolSharkGamesMoonGroup.rotation, {z: 0, y: Math.PI * 4, duration: 2 })
        t2.to(this.poolSharkGamesMoonGroup.rotation, {z: 0, y: 0, duration: 0 })
        t2.to(this.poolSharkGamesMoonGroup.rotation, {x: 0, y: 0, z: 0, duration: 40 })
    }
    

    gsapFunctionForTinCircusMoon()
    {

    const xValueByMousePosition = -this.mouse.gamePlanetPosition.x;

    //was
    // window.innerWidth * 0.0025

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".webgl",
            start: window.innerHeight * 3,
            end: window.innerHeight * 18,
            scrub: true,
            markers: false
        }
        });
        tl.to(this.tinCircusGamesMoonGroup.position, {x: window.innerWidth * 0.06, duration: 0, y: 0, z: -100, duration: 0})
        tl.to(this.tinCircusGamesMoonGroup.position, {z: -1.5, y: this.gamePlanetsHeight, x: xValueByMousePosition, duration: 40})
        tl.to(this.tinCircusGamesMoonGroup.position, {x: window.innerWidth * 0.06, duration: 0, y: 0, z: -100, duration: 40})

    const t2 = gsap.timeline({
        scrollTrigger: {
            trigger: ".webgl",
            start: window.innerHeight * 3,
            end: window.innerHeight * 18,
            scrub: true,
            markers: false
        }
        });
        t2.to(this.tinCircusGamesMoonGroup.rotation, {x: 0, y: 0, z: 0, duration: 38 })
        t2.to(this.tinCircusGamesMoonGroup.rotation, {z: 0, y: -Math.PI * 4, duration: 2 })
        t2.to(this.tinCircusGamesMoonGroup.rotation, {z: 0, y: 0, duration: 0 })
        t2.to(this.tinCircusGamesMoonGroup.rotation, {x: 0, y: 0, z: 0, duration: 40 })
    }


    update()
    { 
        if(this.heavyBucketMoon) 
        {

            if(this.app.scrollPageNumbers.page === 1 || this.app.scrollPageNumbers.page === 18) {
                // moon come back
                if(this.heavyBucketGamesMoonGroup.position.z < this.heavyBucketMoonStartingZAxis) 
                {
                    let color = 100 - this.heavyBucketGamesMoonGroup.position.z;
                    color = (color - 100) * 0.015;
                    color = 1 - color;
                    // 0.2 is the darkest it goes
                    color < 0.1 ? color = 0.1 : color;
                    // console.log(this.heavyBucketGamesMoonGroup.children[0]);
                    this.heavyBucketGamesMoonGroup.children[0].children[2].material.color = new THREE.Color(color, color, color);

                    let audioValue = 100 - this.heavyBucketGamesMoonGroup.position.z;
                    audioValue = ((audioValue - 100) * 0.03);
                    audioValue = 1 - audioValue;
                    // 0.2 is the darkest it goes
                    audioValue < 0.001 ? audioValue = 0 : audioValue;
                    if(localStorage.getItem('audio') === 'true')
                    {
                        this.audioManager.listener.setVolume(audioValue);
                    }
                }
            }
            else
            {
                // moon fuckoff
                if(this.heavyBucketGamesMoonGroup.position.z > (-this.camera.cullingDistance) + 1) 
                {
                    let color = 100 - this.heavyBucketGamesMoonGroup.position.z;
                    color = (color - 100) * 0.015;
                    color = 1 - color;
                    // 0.2 is the darkest it goes
                    color < 0.1 ? color = 0.1 : color;
                    this.heavyBucketGamesMoonGroup.children[0].children[2].material.color = new THREE.Color(color, color, color);

                    let audioValue = 100 - this.heavyBucketGamesMoonGroup.position.z;
                    audioValue = (audioValue - 100) * 0.03;
                    audioValue = 1 - audioValue;
                    // 0.2 is the darkest it goes
                    audioValue < 0.001 ? audioValue = 0 : audioValue;
                    if(localStorage.getItem('audio') === 'true')
                    {
                        this.audioManager.listener.setVolume(audioValue);
                    }
            }
            }
        }

        if(this.pointerSprite)
        {
            this.pointerSprite.update();
        }

        if(this.galaxy)
        {
            this.galaxy.update();
        }

        if(this.enemyOneMaster)
        {
            this.enemyOneMaster.update();
        }

        if(this.scoreText) 
        {
            this.scoreText.update();
        }

        if(this.rocket)
        {
            this.rocket.update();
        }

        if(this.floor)
        {
            this.floor.update();
        }

        if(this.man) 
        {
            this.man.update();
        }
        
        if(this.galaxy) 
        {
            this.galaxyGroup.rotation.y = -(window.scrollY * 0.00005);
            this.galaxy.update();
        }

        if(this.space) 
        {
            this.spaceGroup.rotation.y = (window.scrollY * 0.00005);
        }


        // controls tin circus moon movement (righthand planet)
        if(this.tinCircusMoon && this.tinCircusGamesMoonGroup.children[0])
        {
            switch(this.app.scrollPageNumbers.page)
            {
                case 9:
                    this.tinCircusGamesMoonGroup.children[0].children[5].userData.clickable = true;
                break;

                case 10:
                    this.tinCircusGamesMoonGroup.children[0].children[5].userData.clickable = true;
                break;

                case 11:
                        this.tinCircusGamesMoonGroup.children[0].children[5].userData.clickable = true;
                    break;
                case 12:
                        this.tinCircusGamesMoonGroup.children[0].children[5].userData.clickable = true;
                    break;
                default:
                        this.tinCircusGamesMoonGroup.children[0].children[5].userData.clickable = false;
                    break;
            }
        }
        // controls poolshark moon movement (lefthand planet)
        if(this.poolSharkMoon && this.poolSharkGamesMoonGroup.children[0])
        {
            switch(this.app.scrollPageNumbers.page)
            {
                case 9:
                    this.poolSharkGamesMoonGroup.children[0].children[5].userData.clickable = true;
                break;

                case 10:
                    this.poolSharkGamesMoonGroup.children[0].children[5].userData.clickable = true;
                break;

                case 11:
                        this.poolSharkGamesMoonGroup.children[0].children[5].userData.clickable = true;
                    break;
                case 12:
                        this.poolSharkGamesMoonGroup.children[0].children[5].userData.clickable = true;
                    break;
                default:
                        this.poolSharkGamesMoonGroup.children[0].children[5].userData.clickable = false;
                    break;
            }
        }
    }
}  