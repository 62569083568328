import * as THREE from 'three';
import App from "../App";
import Helper from '../Utils/Helper';
import BulletVertexShader from '../Shaders/bullet/vertex.glsl';
import BulletFragmentShader from '../Shaders/bullet/fragment.glsl';

export default class Bullet
{
    constructor(color, parentClass, gameScene) 
    {
        this.helper = new Helper();
        this.app = new App();
        this.scene = this.app.scene;
        this.resources = this.app.resources;
        this.camera = this.app.camera;
        this.enemySpawner = this.app.enemySpawner;
        this.world = this.app.world;
        this.color = color;
        this.gameScene = gameScene;
        this.parentClass = parentClass;
        this.scoreText = this.world.scoreText;
        this.time = this.app.time;
        
        // cache
        this.bulletStartPosition = new THREE.Vector3(0,0,0);
        this.instance;
        this.direction = new THREE.Vector3(0,0,0);
        this.directionNormalised = new THREE.Vector3(0,0,0);
        this.speed = 0;
        this.raycaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(), 0, 0.2);
        this.raycaster.camera = this.camera.instance;
        this.value = 175;
        this.colorInRgb = new THREE.Color(this.color);

        this.start();

        // console.log(this.scene.children);
    }

    start() 
    {
        //instantiate bullet or laser
        this.instance = new THREE.Mesh(
            new THREE.BoxBufferGeometry(1,1,10),
            new THREE.ShaderMaterial({
                vertexShader: BulletVertexShader,
                fragmentShader: BulletFragmentShader,
                uniforms: 
                {
                    color: 
                    {
                        value: new THREE.Vector3(this.colorInRgb.r, this.colorInRgb.g, this.colorInRgb.b)
                    }
                }
            })
        )
        this.instance.scale.set(0.03,0.03,0.03);
        // this.instance.scale.set(0.3,0.3,0.3);
        this.instance.name = `bullet${this.parentClass}`;
        this.instance.userData['parentClass'] = this.parentClass;
        this.instance.castShadow = false;
    }

    setBulletPosition(position) 
    {

        this.instance.position.set(position.x, position.y, position.z);
        if(!this.gameScene)
        {
            this.gameScene = this.app.world.gameScene;
        }
        this.gameScene.add(this.instance);
    }

    remove()
    {
        this.gameScene.remove(this.instance);
    }

    setVelocity(direction, mouseScreenPosition) 
    {
        this.direction = direction;
        this.instance.lookAt(mouseScreenPosition);
    }

    setSpeed(s)
    {
        s *= 0.05;
        this.speed = s;
    }

    update()
    {
        this.directionNormalised.set(this.direction.x, this.direction.y, this.direction.z).normalize();
        this.instance.position.x += this.directionNormalised.x * this.speed * this.time.delta;
        this.instance.position.y += this.directionNormalised.y * this.speed * this.time.delta;
        this.instance.position.z += this.directionNormalised.z * this.speed * this.time.delta;

        this.raycaster.set(this.instance.position,  this.directionNormalised);
        const intersects = this.raycaster.intersectObjects( this.scene.children[9].children );

        if(intersects.length > 0) 
        {
            intersects.forEach((x) => {
                x.object.traverse((c) => {
                    if(c instanceof THREE.Mesh) 
                    {
                        if(this.instance.userData.parentClass === 'Rocket' && (c.name === "EnemyOneCube" || c.name === "enemyOne"))
                        {
                            // console.log(c);
                            const np = new THREE.Vector3(c.position.x, c.position.y, c.position.z);
                            c.setPositionFunction(this.enemySpawner.spawnPositionsArrayPageOne[this.helper.getRandomInt(0, this.enemySpawner.spawnPositionsNorth)]);
                            c.active = false;
                            if(!this.rocket)
                            {
                                this.rocket = this.app.world.rocket;
                            }
                            this.rocket.explosionImpact(np, 'red')
                            this.gameScene.remove(this.instance);
                            // console.log('hit enemy', c);
                            this.gameScene.remove(c);
    
                            if(this.scoreText)
                            {
                                this.scoreText.add(this.value);
                            }
                        }
                        if(!this.rocket)
                        {
                            this.rocket = this.app.world.rocket;
                        }
                        if(this.rocket.rocketDead === false && (this.instance.userData.parentClass === 'EnemyOne' && (c.name === "RocketCube" || c.name === "Rocket")))
                        {
                            // remove bullet
                            if(!this.gameScene)
                            {
                                this.gameScene = this.app.world.gameScene;
                            }
                            this.gameScene.remove(this.instance);

                            // kill rocket
                            if(!this.scoreText)
                            {
                                this.scoreText = this.app.world.scoreText;
                            }

                            // store in locale storage
                            if(this.scoreText.newHighScore === false && (!localStorage.getItem('score') || parseInt(localStorage.getItem('score')) < this.scoreText.score))
                            {
                                localStorage.setItem('score', this.scoreText.score);
                                this.scoreText.newHighScore = true;
                            }

                            this.scoreText.scoreWaitingRoom = 1000000;
                            this.scoreText.level = 1;
                            this.rocket.rocketDead = true;
                            this.rocket.didILeaveThePageWhileRocketWasStillAlive = false;
                            this.rocket.group.remove(this.rocket.instance);
                            this.rocket.group.remove(this.rocket.instanceCube);
                            const np = new THREE.Vector3(this.instance.position.x, this.instance.position.y, this.instance.position.z)
                            this.rocket.explosionImpact(np, 'blue');

                            //run game over
                        }

                        if(c.name === 'explosion')
                        {
                            this.gameScene.remove(this.instance);
                            this.rocket.group.remove(this.rocket.instanceCube);
                        }
                    }
                })
            })
        }
    }
}