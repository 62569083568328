import App from "../App";
import EventEmitter from "./EventEmitter";

export default class ScrollManager extends EventEmitter{

    constructor() 
    {
        super();
        this.scrollDetect();
    }

    scrollDetect() 
    {
            window.addEventListener('scroll', (e) => {
                this.trigger('three-scroll', [e]);
            }
    )}
}